import { Icon, IconifyIcon } from '@iconify/react';
import { SnackbarProvider } from 'notistack';
import infoFill from '@iconify/icons-eva/info-fill';
import alertCircleFill from '@iconify/icons-eva/alert-circle-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
import checkmarkCircle2Fill from '@iconify/icons-eva/checkmark-circle-2-fill';
// material
import { useTheme } from '@mui/material/styles';
import { Box, GlobalStyles } from '@mui/material';
import { getColor } from '../utils/palette';

// ----------------------------------------------------------------------

function SnackbarStyles() {
  const theme = useTheme();

  return (
    <GlobalStyles
      styles={{
        '& .SnackbarContent-root': {
          width: '100%',
          padding: theme.spacing(1.5),
          margin: theme.spacing(0.25, 0),
          boxShadow: theme.shadows[16],
          borderRadius: theme.shape.borderRadius,
          color: theme.palette.grey[50],
          backgroundColor: theme.palette.grey[900]
        },
        '& .SnackbarItem-action': {
          marginRight: 0,
          color: theme.palette.action.active,
          '& svg': { width: 20, height: 20 }
        },
        '&.notistack-MuiContent-success': {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.background.paper,
          fontWeight: theme.typography.fontWeightMedium
        },
        '&.notistack-MuiContent-error': {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.background.paper,
          fontWeight: theme.typography.fontWeightMedium
        }
      }}
    />
  );
}

type SnackbarIconProps = {
  icon: IconifyIcon;
  color: string;
};

const SnackbarIcon: React.FC<SnackbarIconProps> = ({ icon, color }) => {
  return (
    <Box
      component="span"
      sx={{
        mr: 1.5,
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        justifyContent: 'center',
        color: (theme) => `${getColor(theme, color).main}`,
        bgcolor: (theme) => theme.palette.background.paper
        // bgcolor: (theme) => alpha(`${getColor(theme, color).main}`, 0.16)
      }}
    >
      <Icon icon={icon} width={24} height={24} />
    </Box>
  );
};

type NotistackProviderProps = {
  children: any;
};

const NotistackProvider: React.FC<NotistackProviderProps> = ({ children }) => {
  return (
    <>
      <SnackbarStyles />

      <SnackbarProvider
        dense
        maxSnack={5}
        // preventDuplicate
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        iconVariant={{
          success: <SnackbarIcon icon={checkmarkCircle2Fill} color="success" />,
          error: <SnackbarIcon icon={infoFill} color="error" />,
          warning: <SnackbarIcon icon={alertTriangleFill} color="warning" />,
          info: <SnackbarIcon icon={alertCircleFill} color="info" />
        }}
      >
        {children}
      </SnackbarProvider>
    </>
  );
};

export default NotistackProvider;
