import { createContext, useReducer } from "react";
import { NotifyPayloadInterface, NotifyState } from "../types/notifyTypes";

const initialState: NotifyState = {
  error: null,
  showSignUpModal: false,
};

const reducer = (
  state: NotifyState,
  action: { type: string; payload: NotifyPayloadInterface }
): NotifyState => {
  if (action.type === "ERROR") {
    if (!action.payload.error) {
      return {
        ...state,
        error: "Unexpected error occurred.",
      };
    }
    return {
      ...state,
      error: action.payload.error,
    };
  } else if (action.type === "SHOW_SIGNUP") {
    return {
      ...state,
      showSignUpModal: action.payload.showSignUpModal!,
    };
  }
  return state;
};

const notifyActions = {
  setShowSignUp: (val: boolean) => {},
};

// context

const NotifyContext = createContext({
  ...initialState,
  ...notifyActions,
});

interface NotifyProviderProps {
  children: React.ReactNode;
}

function NotifyProvider({ children }: NotifyProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setShowSignUp = (val: boolean) => {
    dispatch({ type: "SHOW_SIGNUP", payload: { showSignUpModal: val } });
  };

  return (
    <NotifyContext.Provider
      value={{ ...state, ...notifyActions, setShowSignUp }}
    >
      {children}
    </NotifyContext.Provider>
  );
}

export { NotifyProvider, NotifyContext };
