import SignUpPopUp from "../components/modals/SignUpPopUp";
import useNotify from "../hooks/useNotify";

interface NotifyModalsProps {
  children: React.ReactNode;
}

const NotifyModals: React.FC<NotifyModalsProps> = ({ children }) => {
  const { showSignUpModal, setShowSignUp } = useNotify();

  return (
    <>
      <SignUpPopUp
        open={showSignUpModal}
        onClose={() => setShowSignUp(false)}
      />
      {children}
    </>
  );
};

// modals
export default NotifyModals;
