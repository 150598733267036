// ----------------------------------------------------------------------

function buildpath(root: string, sublink: string) {
  return `${root}/${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_APP = "";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: buildpath(ROOTS_AUTH, "login"),
  register: buildpath(ROOTS_AUTH, "register"),
  resetPassword: buildpath(ROOTS_AUTH, "reset-password"),
};

export const PATH_APP = {
  root: ROOTS_APP + "/",
  profile: {
    root: buildpath(ROOTS_APP, "profile"),
    me: buildpath(ROOTS_APP, "profile/me"),
    partner: {
      root: buildpath(ROOTS_APP, "profile/partner"),
      add: buildpath(ROOTS_APP, "profile/partner/add"),
      edit: buildpath(ROOTS_APP, "profile/partner/edit"),
      branch: {
        add: buildpath(ROOTS_APP, "profile/partner/branch/add"),
        edit: buildpath(ROOTS_APP, "profile/partner/branch/edit/:branchId"),
      },
    },
    external: {
      tyc: "https://ecopa.mx/terminos-y-condiciones",
      privacy: "https://ecopa.mx/aviso-de-privacidad",
      company: "https://ecopa.mx/nosotros",
    },
  },
  explore: {
    root: buildpath(ROOTS_APP, "explore"),
    map: buildpath(ROOTS_APP, "explore/map"),
    search: buildpath(ROOTS_APP, "explore/search"),
    cafe: buildpath(ROOTS_APP, "explore/cafe/:branchId"),
  },
  home: {
    root: buildpath(ROOTS_APP, "home"),
  },
  admin: {
    root: buildpath(ROOTS_APP, "admin"),
    config: buildpath(ROOTS_APP, "admin/config"),
    users: buildpath(ROOTS_APP, "admin/users"),
    partners: buildpath(ROOTS_APP, "admin/partners"),
    admins: buildpath(ROOTS_APP, "admin/admins"),
  },
};
