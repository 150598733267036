/**
 * Configuration interfaces
 */
interface firebaseConfigInterface {
  apiKey: string | undefined;
  authDomain: string | undefined;
  databaseURL: string | undefined;
  projectId: string | undefined;
  storageBucket: string | undefined;
  messagingSenderId: string | undefined;
  appId: string | undefined;
  measurementId: string | undefined;
}

interface apiConfigInterface {
  host: string;
  stripePublicKey: string;
  helpEmail: string;
  recommendEmail: string;
  referUrl: string;
}

/**
 * Configuration implementations
 */
export const firebaseConfig: firebaseConfigInterface = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const ecopaApiConfig: apiConfigInterface = {
  host: process.env.REACT_APP_ECOPA_API_HOST || "",
  stripePublicKey: process.env.REACT_APP_STRIPE_PK || "",
  helpEmail: process.env.REACT_APP_ECOPA_HELP_EMAIL || "tech.ecopa@gmail.com",
  recommendEmail:
    process.env.REACT_APP_ECOPA_RECOMMEND_EMAIL || "tech.ecopa@gmail.com",
  referUrl:
    process.env.REACT_APP_ECOPA_REFER_URL || "https://ecopa-pwa.onrender.com",
};

export const googleAnalyticsConfig: string | undefined =
  process.env.REACT_APP_GA_MEASUREMENT_ID;

export const googleMapsApiKey: string =
  process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "";

export const googlleMapsMapId: string =
  process.env.REACT_APP_GOOGLE_MAPS_MAP_ID || "6e61f16f01c7b1be";

export const appVersion: string = "v1.0.1";
