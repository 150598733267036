import { ecopaApiConfig } from "../config";
import { EcopaFeature, EcopaTag } from "../types/ecopaConfig";
import type {
  EcopaBranchImage,
  EcopaBranchImageCreate,
  EcopaBranchReview,
  EcopaCupCount,
  EcopaFeatureValue,
  EcopaPartnerBranch,
  EcopaPartnerBranchBase,
  EcopaPartnerBranchWithFeatures,
} from "../types/ecopaPartner";
import {
  authedFetchGet,
  authedFetchPost,
  authedFetchPostWithFiles,
} from "../utils/authedRequest";
import { convertErrorToString } from "../utils/errors";
import { ApiError } from "./apiError";

const ecopaPartnerBranchEndpoints = {
  newBranch: ecopaApiConfig.host + "/partner/branch/",
  updateBranch: ecopaApiConfig.host + "/partner/branch/",
  deleteBranch: ecopaApiConfig.host + "/partner/branch/",
  updateBranchWithImgs: ecopaApiConfig.host + "/partner/branch/images/",
  getBranches: ecopaApiConfig.host + "/partner/branch/",
};

type EcopaPartnerBranchApi = {
  branch: EcopaPartnerBranch;
  features: {
    id: string;
    feature_value: boolean;
    ecopa_partner_branch_id: string;
    ecopa_feature_id: number;
    created_at: Date;
    last_updated: Date;
  }[];
  tags: EcopaTag[];
  images: EcopaBranchImage[];
  last_cup_count?: EcopaCupCount;
};

/**
 *  Post a new Ecopa Partner Branch
 * @param ecopaBranchInfo
 * @param allFeatures
 * @returns
 */
export async function postNewEcopaPartnerBranch(
  ecopaBranchInfo: {
    branch: EcopaPartnerBranchBase;
    images: EcopaBranchImageCreate[];
    features: EcopaFeature[];
    tags: string[];
  },
  allFeatures: EcopaFeature[]
): Promise<{
  branches?: EcopaPartnerBranchWithFeatures[];
  error?: string;
}> {
  try {
    const brFormData = new FormData();
    for (const img of ecopaBranchInfo.images) {
      brFormData.append("images", img.imageFile, img.imageFile.name);
    }
    brFormData.append("branch", JSON.stringify(ecopaBranchInfo.branch));
    brFormData.append("features", JSON.stringify(ecopaBranchInfo.features));
    brFormData.append("tags", JSON.stringify(ecopaBranchInfo.tags));
    if (ecopaBranchInfo.images.length > 0) {
      brFormData.append(
        "image_alts",
        JSON.stringify(ecopaBranchInfo.images.map((img) => img.imageAlt))
      );
    }

    // post the new partner branch
    const respBranch: EcopaPartnerBranchApi[] | ApiError =
      await authedFetchPostWithFiles({
        endpoint: ecopaPartnerBranchEndpoints.newBranch,
        data: brFormData,
      });

    if ("detail" in respBranch) {
      throw new Error(JSON.stringify(respBranch.detail));
    }

    // parse data
    const eBranches = respBranch.map((branch) => {
      // format features
      const eFeat: EcopaFeatureValue[] = allFeatures.map((f) => {
        const feat = branch.features.find((bf) => bf.ecopa_feature_id === f.id);
        if (!feat) {
          return { ...f, feature_value: false };
        }
        return {
          ...f,
          feature_value: feat.feature_value,
          ecopa_branch_feature_id: feat.id,
        };
      });
      const eBranch = {
        ...branch.branch,
        tags: branch.tags,
        images: branch.images,
        features: eFeat,
        reviews: [] as EcopaBranchReview[],
      } as EcopaPartnerBranchWithFeatures;
      return eBranch;
    });

    // return data
    return {
      branches: eBranches,
    };
  } catch (error) {
    console.error("Error in postNewEcopaPartnerBranch:", error);
    return { error: convertErrorToString(error) };
  }
}

/**
 *  Get the Ecopa Partner Branches
 * @param ecopaPartnerId
 * @param allFeatures
 * @returns
 */
export async function getEcopaPartnerBranches(
  ecopaPartnerId: string,
  allFeatures: EcopaFeature[]
): Promise<{
  branches?: EcopaPartnerBranchWithFeatures[];
  error?: string;
}> {
  try {
    // get the partner branches
    const respBranch: EcopaPartnerBranchApi[] | ApiError = await authedFetchGet(
      {
        endpoint: ecopaPartnerBranchEndpoints.getBranches,
        data: { partner_id: ecopaPartnerId },
      }
    );

    if ("detail" in respBranch) {
      throw new Error(JSON.stringify(respBranch.detail));
    }

    // parse data
    const eBranches = respBranch.map((branch) => {
      // format features
      const eFeat: EcopaFeatureValue[] = allFeatures.map((f) => {
        const feat = branch.features.find((bf) => bf.ecopa_feature_id === f.id);
        if (!feat) {
          return { ...f, feature_value: false };
        }
        return {
          ...f,
          feature_value: feat.feature_value,
          ecopa_branch_feature_id: feat.id,
        };
      });
      const eBranch = {
        ...branch.branch,
        tags: branch.tags,
        images: branch.images,
        features: eFeat,
        reviews: [] as EcopaBranchReview[],
      } as EcopaPartnerBranchWithFeatures;
      return eBranch;
    });

    // return data
    return {
      branches: eBranches,
    };
  } catch (error) {
    console.error("Error in getEcopaPartnerBranches:", error);
    return { error: convertErrorToString(error) };
  }
}

/**
 *  Post an update Ecopa Partner Branch
 * @param ecopaBranchInfo
 * @param allFeatures
 * @returns
 */
export async function putUpdateEcopaPartnerBranch(
  ecopaBranchInfo: {
    branch: EcopaPartnerBranch;
    newImages: EcopaBranchImageCreate[];
    editImages: EcopaBranchImage[];
    features: EcopaFeature[];
    tags: string[];
  },
  allFeatures: EcopaFeature[]
): Promise<{
  branches?: EcopaPartnerBranchWithFeatures[];
  error?: string;
}> {
  try {
    const brFormData = new FormData();
    for (const img of ecopaBranchInfo.newImages) {
      brFormData.append("new_images", img.imageFile, img.imageFile.name);
    }
    brFormData.append("branch", JSON.stringify(ecopaBranchInfo.branch));
    brFormData.append(
      "edit_images",
      JSON.stringify(ecopaBranchInfo.editImages)
    );
    brFormData.append("features", JSON.stringify(ecopaBranchInfo.features));
    brFormData.append("tags", JSON.stringify(ecopaBranchInfo.tags));

    // post the new partner branch
    let respBranch: EcopaPartnerBranchApi[] | ApiError;
    if (ecopaBranchInfo.newImages.length > 0) {
      // add image alts for new images
      brFormData.append(
        "new_image_alts",
        JSON.stringify(ecopaBranchInfo.newImages.map((img) => img.imageAlt))
      );
      respBranch = await authedFetchPostWithFiles({
        endpoint: ecopaPartnerBranchEndpoints.updateBranchWithImgs,
        data: brFormData,
        httpMethod: "PUT",
      });
    } else {
      respBranch = await authedFetchPostWithFiles({
        endpoint: ecopaPartnerBranchEndpoints.updateBranch,
        data: brFormData,
        httpMethod: "PUT",
      });
    }

    if ("detail" in respBranch) {
      throw new Error(JSON.stringify(respBranch.detail));
    }

    // parse data
    const eBranches = respBranch.map((branch) => {
      // format features
      const eFeat: EcopaFeatureValue[] = allFeatures.map((f) => {
        const feat = branch.features.find((bf) => bf.ecopa_feature_id === f.id);
        if (!feat) {
          return { ...f, feature_value: false };
        }
        return {
          ...f,
          feature_value: feat.feature_value,
          ecopa_branch_feature_id: feat.id,
        };
      });
      const eBranch = {
        ...branch.branch,
        tags: branch.tags,
        images: branch.images,
        features: eFeat,
        reviews: [] as EcopaBranchReview[],
      } as EcopaPartnerBranchWithFeatures;
      return eBranch;
    });

    // return data
    return {
      branches: eBranches,
    };
  } catch (error) {
    console.error("Error in putUpdateEcopaPartnerBranch:", error);
    return { error: convertErrorToString(error) };
  }
}

/**
 *  Delete Ecopa Partner Branch
 * @param ecopaBranchId
 * @returns
 */
export async function deleteEcopaPartnerBranch(
  ecopaBranchId: string,
  ecopaPartnerId: string
): Promise<{
  success?: boolean;
  error?: string;
}> {
  try {
    // post the new partner branch
    let respBranch: { status: boolean; msg: string } | ApiError;
    respBranch = await authedFetchPost({
      endpoint: ecopaPartnerBranchEndpoints.deleteBranch,
      data: {
        branch_id: ecopaBranchId,
        partner_id: ecopaPartnerId,
      },
      httpMethod: "DELETE",
    });

    if ("detail" in respBranch) {
      throw new Error(JSON.stringify(respBranch.detail));
    }

    // return data
    return {
      success: respBranch.status,
    };
  } catch (error) {
    console.error("Error in deleteEcopaPartnerBranch:", error);
    return { error: convertErrorToString(error) };
  }
}
