import { useEffect, useRef } from "react";
import LoadingScreen from "../../components/LoadingScreen";
import useAppConfig from "../../hooks/useAppConfig";
import { Box } from "@mui/material";

export interface SessionWatchProps {
  children: React.ReactNode;
}

function SessionWatch({ children }: SessionWatchProps): JSX.Element {
  const initRef = useRef(false);
  const { loading, loaded, config, initConfig } = useAppConfig();

  useEffect(() => {
    if (initRef.current) {
      return;
    }
    initConfig();
    initRef.current = true;
  }, [initConfig]);

  if ((config.length === 0 && !loaded) || loading) {
    return (
      <Box sx={{ height: "90vh" }}>
        <LoadingScreen />
      </Box>
    );
  }

  if (config.length === 0 && loaded) {
    // [TODO] show error message
    return <div> Error: Por favor intenta más tarde</div>;
  }
  return <>{children}</>;
}

export default SessionWatch;
