import { googleMapsApiKey } from "../config";
import { AppConfigProvider } from "./AppConfigContext";
import { NotifyProvider } from "./NotifyContext";
import { PartnerProvider } from "./PartnerContext";
import SessionWatch from "./watch/SessionWatch";
import { APIProvider } from "@vis.gl/react-google-maps";

interface CustomProvidersProps {
  children: React.ReactNode;
}

function CustomProviders({ children }: CustomProvidersProps): JSX.Element {
  return (
    <AppConfigProvider>
      <NotifyProvider>
        <PartnerProvider>
          <APIProvider
            apiKey={googleMapsApiKey}
            onLoad={() => console.log("Maps API has loaded.")}
          >
            {children}
          </APIProvider>
        </PartnerProvider>
      </NotifyProvider>
    </AppConfigProvider>
  );
}

export default CustomProviders;
export { SessionWatch };
