import { googleAnalyticsConfig } from '../config';

// ----------------------------------------------------------------------

type PageViewType = {
  command: string;
  targetId?: string;
  config?: any;
};

type EventType = {
  command: string;
  eventName?: string;
  eventParams?: any;
};

declare let gtag: Function;

const setup = (evType: PageViewType & EventType) => {
  if (process.env.NODE_ENV !== 'production') return;
  if (!window.gtag) return;
  // Event type
  // window.gtag(evType); // [TODO] fix this -- https://nandolinhares.medium.com/google-analytics-with-react-and-typescript-june-2021-12d6f48f4842
  gtag(evType);
};

const track = {
  pageview: (props: any) => {
    setup({
      command: 'config',
      targetId: googleAnalyticsConfig,
      config: props
    });
  },
  event: (type: string, props: object) => {
    if (process.env.NODE_ENV !== 'production') return;
    setup({ command: 'event', eventName: type, eventParams: props });
  }
};

export default track;
