export function convertErrorToString(error: unknown): string {
  if (typeof error === "string") {
    return error;
  } else if (error instanceof Error) {
    // If it's an Error object, return its message
    return error.message;
  } else {
    // For other types, try converting to string in a general way
    return String(error);
  }
}
