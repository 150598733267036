import { InlineIcon } from "@iconify/react";
import { CustomIconProps } from ".";

const homeFillSVG = {
  width: 24,
  height: 24,
  body: '<path fill="currentColor" d="M4 21V9l8-6l8 6v12h-6v-7h-4v7z" />',
};

const HomeFill = ({
  height = 24,
  width = 24,
  color = "currentColor",
  style,
}: CustomIconProps) => {
  return (
    <InlineIcon
      icon={homeFillSVG}
      width={width}
      height={height}
      color={color}
      style={style}
    />
  );
};

export default HomeFill;
