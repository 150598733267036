// hooks
import { useLocation, useNavigate } from "react-router";
// material
import {
  AppBar,
  Box,
  Grid,
  Toolbar,
  Typography,
  alpha,
  styled,
  useTheme,
} from "@mui/material";
import { PATH_APP, PATH_AUTH } from "../../routes/paths";
// components
import HomeFill from "../../components/icons/HomeFill";
import ExploreFill from "../../components/icons/ExploreFill";
import PersonFill from "../../components/icons/PersonFill";

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;

const APPBAR_OPTIONS = [
  {
    label: "Home",
    key: "home",
    icon: HomeFill,
    paths: [PATH_APP.root],
    linkTo: PATH_APP.root,
  },
  {
    label: "Explorar",
    key: "explore",
    icon: ExploreFill,
    paths: [
      PATH_APP.explore.root,
      PATH_APP.explore.map,
      PATH_APP.explore.search,
      PATH_APP.explore.cafe,
    ],
    linkTo: PATH_APP.explore.root,
  },
  {
    label: "Perfil",
    key: "profile",
    icon: PersonFill,
    paths: [
      PATH_AUTH.login,
      PATH_AUTH.register,
      PATH_AUTH.resetPassword,
      PATH_APP.profile.root,
      PATH_APP.profile.me,
      PATH_APP.profile.partner.root,
      PATH_APP.profile.partner.add,
      PATH_APP.profile.partner.edit,
      PATH_APP.profile.partner.branch.add,
      PATH_APP.profile.partner.branch.edit,
    ],
    linkTo: PATH_APP.profile.root,
  },
];

// ----------------------------------------------------------------------

const AppBarStyled = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(8px)",
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  top: "auto",
  bottom: 0,
}));

const BoxFloatBarStyled = styled(Box)(({ theme }) => ({
  height: APPBAR_MOBILE,
  width: "100%",
  backgroundColor: theme.palette.grey[100], // theme.palette.primary.dark,
  boxShadow: theme.shadows[4],
  borderTop: `2px solid ${alpha(theme.palette.common.black, 0.38)}`,
  [theme.breakpoints.up("md")]: {
    marginLeft: "25%",
    width: "50%",
  },
}));

const ToolbarStyled = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  padding: theme.spacing(0, 0),
}));

// ----------------------------------------------------------------------
const isSectionActive = (key: string, lpath: string) => {
  const sIdx = APPBAR_OPTIONS.find((opt) => opt.key === key);
  if (sIdx?.paths.includes(lpath) && key === "home") {
    return true;
  }
  if (
    sIdx?.paths
      .map((p: any) => lpath.includes(p))
      .reduce((a: boolean, b: boolean) => a || b) &&
    key === "explore"
  ) {
    return true;
  }
  if (
    sIdx?.paths
      .map((p: any) => lpath.includes(p))
      .reduce((a: boolean, b: boolean) => a || b) &&
    key === "profile"
  ) {
    return true;
  }
  return false;
};

// ----------------------------------------------------------------------

const BottomAppBar = () => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();

  const goToSection = (sectionPath: string) => {
    navigate(sectionPath);
  };

  return (
    <AppBarStyled position="fixed">
      <ToolbarStyled>
        <BoxFloatBarStyled>
          <Grid container>
            {APPBAR_OPTIONS.map((opt) => {
              const MIcon = opt.icon;
              const mColor = !isSectionActive(opt.key, pathname)
                ? theme.palette.text.primary
                : theme.palette.secondary.main;
              return (
                <Grid item xs={4} sm={4} md={4} key={opt.key}>
                  <Box
                    textAlign={"center"}
                    sx={{
                      mt: 1,
                      cursor: "pointer",
                    }}
                    onClick={() => goToSection(opt.linkTo)}
                  >
                    <MIcon
                      width={28}
                      height={28}
                      color={mColor}
                      style={{
                        color: mColor,
                        fontWeight: theme.typography.fontWeightMedium,
                      }}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        mt: theme.spacing(-0.5),
                        pt: theme.spacing(0),
                        fontSize: "0.65rem",
                        color: !isSectionActive(opt.key, pathname)
                          ? theme.palette.text.primary
                          : theme.palette.secondary.main,
                        fontWeight: theme.typography.fontWeightMedium,
                      }}
                    >
                      {opt.label}
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </BoxFloatBarStyled>
      </ToolbarStyled>
    </AppBarStyled>
  );
};

export default BottomAppBar;
