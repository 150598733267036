import { useEffect, useReducer, createContext } from "react";
import Cookies from "js-cookie";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { firebaseConfig } from "../config";
import { getNewEcopaUser, postNewEcopaUser } from "../api/ecopaUser";

// ----------------------------------------------------------------------

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

type AuthContextUser = {
  id: string;
  email: string;
  photoURL: string;
  firstName: string;
  lastName: string;
  displayName: string;
  role: string;
  countryCode: string;
  phoneNumber?: string;
  isAdmin: boolean;
};

type AuthVerificationType = {
  method: string;
  isVerified: boolean;
  phoneVerificationId: any;
  error: string;
};

type StateType = {
  isAuthenticated: Boolean;
  isInitialized: Boolean;
  verification: AuthVerificationType;
  user: AuthContextUser | null;
};

const initialVerif: AuthVerificationType = {
  method: "",
  isVerified: false,
  phoneVerificationId: null,
  error: "",
};

const initialState: StateType = {
  isAuthenticated: false,
  isInitialized: false,
  verification: initialVerif,
  user: null,
};

const reducer = (state: any, action: any) => {
  if (action.type === "INITIALISE") {
    const { isAuthenticated, user, isVerified } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      verification: {
        ...state.verification,
        isVerified: isVerified,
      },
      user,
    };
  } else if (action.type === "REGISTER_LOGIN") {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  } else if (action.type === "SMS_VERIFICATION") {
    const { phoneVerificationId, isVerified, error } = action.payload;
    return {
      ...state,
      verification: {
        ...state.verification,
        method: "sms",
        phoneVerificationId: phoneVerificationId,
        isVerified: isVerified,
        error: error,
      },
    };
  } else if (action.type === "EMAIL_VERIFICATION") {
    const { isVerified, error } = action.payload;
    return {
      ...state,
      verification: {
        ...state.verification,
        method: "email",
        isVerified: isVerified,
        error: error,
      },
    };
  }

  return state;
};

const AuthContext = createContext({
  ...initialState,
  method: "firebase",
  register: (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    countryCode: string
  ) => Promise.resolve(),
  login: (email: string, password: string) => Promise.resolve(),
  resetPassword: (email: string) => Promise.resolve(),
  loginWithGoogle: (actionType: "login" | "register") => Promise.resolve(),
  //   loginWithFaceBook: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  getSessionToken: () => Promise.resolve(),
  sendPhoneNumberValidation: (verifierContainer: string, phoneNumber: string) =>
    Promise.resolve(),
  sendEmailValidation: () => Promise.resolve(),
  verifyPhoneNumberCode: (verificationCode: string) => Promise.resolve(),
});

type AuthProviderProps = {
  children?: any;
};

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          // verification
          const _isVerified = user.phoneNumber !== null;
          // init routine
          const _initialiseUser = async (fUser: firebase.User) => {
            // set cookie with token
            const token = await fUser.getIdToken();
            Cookies.set("token", token);
            // fetch user info from backend
            const ecoUser = await getNewEcopaUser(token);
            if (ecoUser.error || !ecoUser.ecopaUser) {
              console.error("Error while fetching user info:", ecoUser.error);
              // // logout firebase
              // await firebase.auth().signOut();
              return;
            }
            dispatch({
              type: "INITIALISE",
              payload: {
                isAuthenticated: true,
                user: {
                  id: ecoUser.ecopaUser.id,
                  email: user.email,
                  photoURL: user.photoURL || "",
                  displayName: user.displayName || "",
                  firstName: ecoUser.ecopaUser.firstName,
                  lastName: ecoUser.ecopaUser.lastName,
                  role: "user",
                  phoneNumber: ecoUser.ecopaUser.phoneNumber,
                  countryCode: ecoUser.ecopaUser.countryCode,
                  isAdmin: ecoUser.ecopaUser.admin ?? false,
                },
                isVerified: _isVerified,
              },
            });
          };
          void _initialiseUser(user);
        } else {
          dispatch({
            type: "INITIALISE",
            payload: { isAuthenticated: false, user: null, isVerified: false },
          });
        }
      }), // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const login = async (email: string, password: string) => {
    try {
      const respLog = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      const user = respLog.user;
      if (!user) {
        throw new Error("No user found");
      }
      // set cookie with token
      const token = await user.getIdToken();
      Cookies.set("token", token);
      // fetch user info from backend
      const ecoUser = await getNewEcopaUser(token);
      if (ecoUser.error || !ecoUser.ecopaUser) {
        throw new Error(ecoUser.error);
      }
      // update user info
      dispatch({
        type: "REGISTER_LOGIN",
        payload: {
          isAuthenticated: true,
          user: {
            id: ecoUser.ecopaUser.id,
            email: user.email,
            photoURL: user.photoURL || "",
            displayName: user.displayName || "",
            role: "user",
            firstName: ecoUser.ecopaUser.firstName,
            lastName: ecoUser.ecopaUser.lastName,
            phoneNumber: ecoUser.ecopaUser.phoneNumber,
            countryCode: ecoUser.ecopaUser.countryCode,
            isAdmin: ecoUser.ecopaUser.admin ?? false,
          },
        },
      });
    } catch (error) {
      console.error("Error while logging in:", error);
      throw error;
    }
  };

  const loginWithGoogle = async (actionType: "login" | "register") => {
    const provider = new firebase.auth.GoogleAuthProvider();
    const creds = await firebase.auth().signInWithPopup(provider);
    try {
      const user = creds.user;
      if (!user || !creds.additionalUserInfo?.profile) {
        throw new Error("No user found");
      }
      // set cookie with token
      const token = await user.getIdToken();
      Cookies.set("token", token);
      if (actionType === "register" && creds.additionalUserInfo.isNewUser) {
        // save info into backend
        const additionalGoog = creds.additionalUserInfo.profile as any;
        const userBE = await postNewEcopaUser({
          email: additionalGoog.email || "",
          firstName: additionalGoog.given_name || "",
          lastName: additionalGoog.family_name || "",
          phoneNumber: "",
          countryCode: "",
          firebaseId: user.uid,
        });
        if (userBE.error) {
          // delete user from firebase
          await user.delete();
          throw new Error(userBE.error);
        }
        // update user info
        dispatch({
          type: "REGISTER_LOGIN",
          payload: {
            isAuthenticated: true,
            user: {
              id: userBE.ecopaUser?.id,
              email: user.email,
              photoURL: user.photoURL || "",
              displayName: user.displayName || "",
              firstName: additionalGoog.given_name || "",
              lastName: additionalGoog.family_name || "",
              role: "user",
              phoneNumber: "",
              countryCode: "",
              isAdmin: false,
            },
          },
        });
      } else {
        // fetch user info from backend
        const ecoUser = await getNewEcopaUser(token);
        if (ecoUser.error || !ecoUser.ecopaUser) {
          throw new Error(ecoUser.error);
        }
        // update user info
        dispatch({
          type: "REGISTER_LOGIN",
          payload: {
            isAuthenticated: true,
            user: {
              id: ecoUser.ecopaUser.id,
              email: user.email,
              photoURL: user.photoURL || "",
              displayName: user.displayName || "",
              role: "user",
              firstName: ecoUser.ecopaUser.firstName,
              lastName: ecoUser.ecopaUser.lastName,
              phoneNumber: ecoUser.ecopaUser.phoneNumber,
              countryCode: ecoUser.ecopaUser.countryCode,
              isAdmin: ecoUser.ecopaUser.admin ?? false,
            },
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // const loginWithFaceBook = () => {
  //   const provider = new firebase.auth.FacebookAuthProvider();
  //   return firebase.auth().signInWithPopup(provider);
  // };

  const register = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    countryCode: string
  ) => {
    try {
      const creds = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);
      // update firebase user profile
      const user = creds.user;
      if (!user) {
        throw new Error("No user found");
      }
      await user.updateProfile({
        displayName: `${firstName} ${lastName}`,
      });
      // set cookie with token
      const token = await user.getIdToken();
      Cookies.set("token", token);
      // save info into backend
      const userBE = await postNewEcopaUser({
        email,
        firstName,
        lastName,
        phoneNumber,
        countryCode,
        firebaseId: user.uid,
      });
      if (userBE.error) {
        // delete user from firebase
        await user.delete();
        throw new Error(userBE.error);
      }
      // update user info
      dispatch({
        type: "REGISTER_LOGIN",
        payload: {
          isAuthenticated: true,
          user: {
            id: userBE.ecopaUser?.id,
            email: user.email,
            photoURL: user.photoURL || "",
            displayName: user.displayName || "",
            role: "user",
            firstName,
            lastName,
            phoneNumber,
            countryCode,
            isAdmin: userBE.ecopaUser?.admin ?? false,
          },
        },
      });
    } catch (error) {
      console.error("Error while creating user:", error);
      throw error;
    }
  };

  const logout = async () => {
    await firebase.auth().signOut();
  };

  const resetPassword = async (email: string) => {
    await firebase.auth().sendPasswordResetEmail(email);
  };

  const getSessionToken = async () => {
    const currUser = firebase.auth().currentUser;
    if (currUser !== null) {
      return currUser.getIdToken();
    }
    return new Promise<string>(() => {
      return "";
    });
  };

  const sendPhoneNumberValidation = async (
    verifierContainer: string,
    phoneNumber: string
  ) => {
    firebase.auth().languageCode = "es";
    try {
      const fireAppVerifier = new firebase.auth.RecaptchaVerifier(
        verifierContainer,
        {
          size: "invisible",
          callback: async () => {
            console.log("recaptcha ready");
          },
        }
      );
      fireAppVerifier.render().then(async () => {
        try {
          const provider = new firebase.auth.PhoneAuthProvider();
          console.log("phone number:", phoneNumber);
          console.log("verifier:", fireAppVerifier);
          const verifId = await provider.verifyPhoneNumber(
            phoneNumber,
            fireAppVerifier
          );

          dispatch({
            type: "SMS_VERIFICATION",
            payload: {
              phoneVerificationId: verifId,
              isVerified: false,
              error: "",
            },
          });
        } catch (error) {
          console.error(error);
          dispatch({
            type: "SMS_VERIFICATION",
            payload: {
              phoneVerificationId: "",
              isVerified: false,
              error: "sms_sending_phone_validation_issue",
            },
          });
        }
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: "SMS_VERIFICATION",
        payload: {
          phoneVerificationId: "",
          isVerified: false,
          error: "sms_sending_phone_validation_issue",
        },
      });
    }
  };

  const sendEmailValidation = () => {
    // get current public url from browser
    const publicUrl = window.location.origin;

    firebase
      .auth()
      .currentUser?.sendEmailVerification({
        url: `${publicUrl}`,
      })
      .then(() => {
        dispatch({
          type: "EMAIL_VERIFICATION",
          payload: {
            isVerified: false,
            error: "",
          },
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: "EMAIL_VERIFICATION",
          payload: {
            isVerified: false,
            error: "email_sending_validation_issue",
          },
        });
      });
  };

  const verifyPhoneNumberCode = async (verificationCode: string) => {
    try {
      // get current user
      const prevUser = firebase.auth().currentUser;
      // verify phone number
      const phoneCred = await firebase.auth.PhoneAuthProvider.credential(
        state.verification.phoneVerificationId,
        verificationCode
      );
      const linkResult = await prevUser?.linkWithCredential(phoneCred);
      if (!linkResult) {
        throw Error("Not able to sign in with Phone number!");
      }
      dispatch({
        type: "SMS_VERIFICATION",
        payload: {
          phoneVerificationId: state.verification.phoneVerificationId,
          isVerified: true,
          error: "",
        },
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: "SMS_VERIFICATION",
        payload: {
          phoneVerificationId: state.phoneVerificationId,
          isVerified: false,
          error: "sms_verifying_phone_validation_issue",
        },
      });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "firebase",
        login,
        register,
        loginWithGoogle,
        // loginWithFaceBook,
        logout,
        resetPassword,
        getSessionToken,
        sendPhoneNumberValidation,
        verifyPhoneNumberCode,
        sendEmailValidation,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
