import React from "react";
// themes
import ThemeConfig from "./themes";
import GlobalStyles from "./themes/globalStyles";
// routes
import Router, { appRoutes } from "./routes";
// hooks
import useAuth from "./hooks/useAuth";
// components
import LoadingScreen from "./components/LoadingScreen";
import NotistackProvider from "./components/NotistackProvider";
import ScrollToTop from "./components/ScrollToTop";
import GoogleAnalytics from "./components/GoogleAnalytics";
import CustomProviders, { SessionWatch } from "./contexts";
import NotifyModals from "./guards/NotificationGuard";

const App: React.FC = () => {
  const { isInitialized } = useAuth();

  return (
    // Theme Config Layer
    <ThemeConfig>
      {/* Notification Provider */}
      <NotistackProvider>
        {/* Custom providers for data fetching and context management */}
        <CustomProviders>
          <NotifyModals>
            <SessionWatch>
              {/* Global app styles */}
              <GlobalStyles />
              <ScrollToTop /> {/* Scroll to top of page at load time */}
              <GoogleAnalytics />
              {/* Routed Application  */}
              {isInitialized ? (
                <Router routes={appRoutes} />
              ) : (
                <LoadingScreen />
              )}
            </SessionWatch>
          </NotifyModals>
        </CustomProviders>
      </NotistackProvider>
    </ThemeConfig>
  );
};

export default App;
