import { ecopaApiConfig } from "../config";
import { EcopaUser, EcopaUserBase } from "../types/ecopaUser";
import { authedFetchGet, authedFetchPost } from "../utils/authedRequest";
import { convertErrorToString } from "../utils/errors";
import { ApiError } from "./apiError";

const ecopaUserEndpoints = {
  newUser: ecopaApiConfig.host + "/user/",
  getUser: ecopaApiConfig.host + "/user/",
  updateUser: ecopaApiConfig.host + "/user/",
};

interface EcopaUserApiBase {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  country_code: string;
  firebase_id: string;
}

interface EcopaUserApi extends EcopaUserApiBase {
  id: string;
  active: boolean;
  created_at: Date;
  last_updated: Date;
  admin?: boolean;
}

export async function postNewEcopaUser(
  ecopaUser: EcopaUserBase & { firebaseId: string }
): Promise<{
  ecopaUser?: EcopaUser;
  error?: string;
}> {
  try {
    // post the new user
    const respUser: EcopaUserApi | ApiError = await authedFetchPost({
      endpoint: ecopaUserEndpoints.newUser,
      data: {
        first_name: ecopaUser.firstName,
        last_name: ecopaUser.lastName,
        email: ecopaUser.email,
        phone_number: ecopaUser.phoneNumber,
        country_code: ecopaUser.countryCode,
        firebase_id: ecopaUser.firebaseId,
      } as EcopaUserApiBase,
    });

    if ("detail" in respUser) {
      throw new Error(JSON.stringify(respUser.detail));
    }

    // parse data
    const eUser = {
      id: respUser.id,
      firstName: respUser.first_name,
      lastName: respUser.last_name,
      email: respUser.email,
      phoneNumber: respUser.phone_number,
      countryCode: respUser.country_code,
    } as EcopaUser;

    // return data
    return {
      ecopaUser: eUser,
    };
  } catch (error) {
    console.error("Error in postNewEcopaUser:", error);
    return { error: convertErrorToString(error) };
  }
}

export async function getNewEcopaUser(token: string): Promise<{
  ecopaUser?: EcopaUser;
  error?: string;
}> {
  try {
    // post the new user
    const respUser: EcopaUserApi | ApiError = await authedFetchGet({
      endpoint: ecopaUserEndpoints.getUser,
      headers: {
        Authorization: token,
      },
    });

    if ("detail" in respUser) {
      throw new Error(JSON.stringify(respUser.detail));
    }

    // parse data
    const eUser = {
      id: respUser.id,
      firstName: respUser.first_name,
      lastName: respUser.last_name,
      email: respUser.email,
      phoneNumber: respUser.phone_number,
      countryCode: respUser.country_code,
      admin: respUser.admin,
    } as EcopaUser;

    // return data
    return {
      ecopaUser: eUser,
    };
  } catch (error) {
    console.error("Error in getEcopaUser:", error);
    return { error: convertErrorToString(error) };
  }
}

export async function putUpdateEcopaUser(
  ecopaUser: EcopaUserBase & { id: string }
): Promise<{
  ecopaUser?: EcopaUser;
  error?: string;
}> {
  try {
    if (ecopaUser.phoneNumber === "") {
      return { error: "Phone number cannot be empty" };
    }
    // post the new user
    const respUser: EcopaUserApi | ApiError = await authedFetchPost({
      endpoint: ecopaUserEndpoints.updateUser,
      data: {
        id: ecopaUser.id,
        first_name: ecopaUser.firstName,
        last_name: ecopaUser.lastName,
        email: ecopaUser.email,
        phone_number: ecopaUser.phoneNumber,
        country_code: ecopaUser.countryCode,
      } as EcopaUserApiBase & { id: string },
      httpMethod: "PUT",
    });

    if ("detail" in respUser) {
      throw new Error(JSON.stringify(respUser.detail));
    }

    // parse data
    const eUser = {
      id: respUser.id,
      firstName: respUser.first_name,
      lastName: respUser.last_name,
      email: respUser.email,
      phoneNumber: respUser.phone_number,
      countryCode: respUser.country_code,
    } as EcopaUser;

    // return data
    return {
      ecopaUser: eUser,
    };
  } catch (error) {
    console.error("Error in putUpdateEcopaUser:", error);
    return { error: convertErrorToString(error) };
  }
}
