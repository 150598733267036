import { InlineIcon } from "@iconify/react";
import { CustomIconProps } from ".";

const exploreFillSVG = {
	"width": 24,
	"height": 24,
	"body": "<path fill=\"currentColor\" d=\"m19.6 21l-6.3-6.3q-.75.6-1.725.95T9.5 16q-2.725 0-4.612-1.888T3 9.5t1.888-4.612T9.5 3t4.613 1.888T16 9.5q0 1.1-.35 2.075T14.7 13.3l6.3 6.3zM9.5 14q1.875 0 3.188-1.312T14 9.5t-1.312-3.187T9.5 5T6.313 6.313T5 9.5t1.313 3.188T9.5 14m-2-1.5l.75-2.45l-2-1.6H8.7L9.5 6l.8 2.45h2.45l-2 1.6l.75 2.45l-2-1.55z\" />"
};

const ExploreFill = ({
  height = 24,
  width = 24,
  color = "currentColor",
  style,
}: CustomIconProps) => {
  return (
    <InlineIcon
      icon={exploreFillSVG}
      width={width}
      height={height}
      color={color}
      style={style}
    />
  );
};

export default ExploreFill;
