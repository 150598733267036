import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9F9F9',  // surface
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8)
};

const PRIMARY = {
  lighter: '#4E3C24',
  light: '#90601F',
  main: '#3C280D',
  dark: '#120C04',
  darker: '#120C04',
  contrastText: '#fff'
};
const SECONDARY = {
  lighter: '#60E1DF',
  light: '#60E1DF',
  main: '#11AE5D',
  dark: '#136261',
  darker: '#136261',
  contrastText: '#fff'
};
const INFO = {
  lighter: '#60E1DF',
  light: '#60E1DF',
  main: '#11AE5D',
  dark: '#136261',
  darker: '#136261',
  contrastText: '#fff'
};
const SUCCESS = {
  lighter: '#11AE5D',
  light: '#11AE5D',
  main: '#11AE5D',
  dark: '#11AE5D',
  darker: '#11AE5D',
  contrastText: GREY[800]
};
const WARNING = {
  lighter: '#90601F',
  light: '#90601F',
  main: '#90601F',
  dark: '#90601F',
  darker: '#90601F',
  contrastText: GREY[800]
};
const ERROR = {
  lighter: '#B00020',
  light: '#B00020',
  main: '#B00020',
  dark: '#B00020',
  darker: '#B00020',
  contrastText: '#fff'
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main)
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4']
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48
  }
};

const palette = {
  light: {
    ...COMMON,
    text: { primary: PRIMARY.dark, secondary: PRIMARY.lighter, disabled: GREY[500] },
    background: { paper: '#fff', default: '#fff', neutral: GREY[200] },
    action: { active: GREY[600], ...COMMON.action }
  },
  dark: {
    ...COMMON,
    text: { primary: COMMON.common.white, secondary: GREY[500], disabled: GREY[600] },
    background: { paper: GREY[800], default: PRIMARY.dark, neutral: GREY[500_16] },
    action: { active: GREY[500], ...COMMON.action }
  }
};

export default palette;
