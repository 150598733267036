import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
// hooks
import useAuth from "../hooks/useAuth";
// routes
import { PATH_APP, PATH_AUTH } from "../routes/paths";
import Verification from "../pages/auth/Verification";
import BottomAppBar from "../layouts/appbar/AppBar";
import useAppConfig from "../hooks/useAppConfig";
import { parseRemoteConfig } from "../utils/config";
import { EcopaParsedConfig } from "../types/ecopaConfig";

// ----------------------------------------------------------------------

const pathsWithNecessaryAuth = [
  PATH_APP.profile.root,
  PATH_APP.profile.me,
  PATH_APP.profile.partner.root,
  PATH_APP.profile.partner.add,
  PATH_APP.profile.partner.edit,
  PATH_APP.profile.partner.branch.add,
  PATH_APP.profile.partner.branch.edit.replace(":branchId", ""),
  PATH_APP.admin.root,
  PATH_APP.admin.config,
  PATH_APP.admin.users,
  PATH_APP.admin.partners,
  PATH_APP.admin.admins,
];

const pathsNeedsAdmin = new Set([
  PATH_APP.admin.root,
  PATH_APP.admin.config,
  PATH_APP.admin.users,
  PATH_APP.admin.partners,
  PATH_APP.admin.admins,
]);

const pathNeedsAuth = (path: string) => {
  // iterate over pathsWithNecessaryAuth
  // if path is a substring of any of the paths in pathsWithNecessaryAuth
  // return true
  // otherwise return false
  const verifVec = pathsWithNecessaryAuth.map((p) => {
    if (path.includes(p)) {
      return true;
    }
    return false;
  });
  return verifVec.reduce((acc, curr) => acc || curr, false);
};

const pathNeedsAdmin = (path: string) => pathsNeedsAdmin.has(path);

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: any;
};

const AuthGuard: React.FC<AuthGuardProps> = ({ children }) => {
  const { config } = useAppConfig();
  const { isAuthenticated, verification, user } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string>();
  const [appConfig, setAppConfig] = useState<EcopaParsedConfig>({
    active_login_wall: undefined,
  });

  useEffect(() => {
    if (config.length > 0) {
      setAppConfig(parseRemoteConfig(config));
    }
  }, [config]);

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    // if path needs auth, return login page
    if (pathNeedsAuth(pathname)) {
      return <Navigate to={PATH_AUTH.register} />;
    }
    // if loginWall is Active return login page
    if (
      appConfig.active_login_wall !== undefined &&
      appConfig.active_login_wall &&
      ![PATH_AUTH.register, PATH_AUTH.login, PATH_AUTH.resetPassword].includes(
        pathname
      )
    ) {
      return <Navigate to={PATH_AUTH.register} />;
    }
  } else {
    // if Authenticated - check if there is phone verification
    if (!verification.isVerified) {
      return (
        <>
          <Verification />
          <BottomAppBar />
        </>
      );
    }
  }

  // verify for admin users
  if (isAuthenticated && pathNeedsAdmin(pathname) && !user?.isAdmin) {
    return <Navigate to={PATH_APP.root} />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(undefined);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

export default AuthGuard;
