import React, { Suspense, lazy } from "react";
import { Navigate, Route, RouteProps, Routes } from "react-router-dom";
// layouts
import AppBarLayout from "../layouts/appbar";
// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
// components
import LoadingScreen from "../components/LoadingScreen";
import { PATH_APP, PATH_AUTH } from "./paths";

// ----------------------------------------------------------------------
type IterativeRouteProps = {
  route: RouteProps;
  layout?: JSX.Element;
  subpaths?: Array<RouteProps>;
};

type RouterProps = {
  routes: Array<IterativeRouteProps>;
};

const Router: React.FC<RouterProps> = ({ routes }) => {
  const routeBuilder = () => {
    const builtRs = [];
    for (const k in routes) {
      const rout = routes[k];
      const layRoutes = [];
      if (rout.layout === undefined) {
        // if nno layout - set route alone
        builtRs.push(<Route key={rout.route.path} {...rout.route} />);
      } else {
        layRoutes.push(<Route key={"l-" + rout.route.path} {...rout.route} />);
      }
      if (rout.subpaths !== undefined) {
        for (const s in rout.subpaths) {
          const srout = rout.subpaths[s];
          const newpath = [rout.route.path || "", srout.path].join("/");
          if (rout.layout === undefined) {
            // if no layout - set route alone
            builtRs.push(<Route key={newpath} {...srout} path={newpath} />);
          } else {
            layRoutes.push(<Route key={newpath} {...srout} path={newpath} />);
          }
        }
      }
      // if layout add it on top of the route tree
      if (layRoutes.length > 0) {
        builtRs.push(
          <Route key={rout.route.path} element={rout.layout}>
            {layRoutes.map((lr) => lr)}
          </Route>
        );
      }
    }
    return builtRs;
  };

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Routes>{routeBuilder().map((rout) => rout)}</Routes>
    </Suspense>
  );
};

// IMPORT COMPONENTS
// Home Screen
const Home = lazy(() => import("../pages/Home"));

// Authentication
const Login = lazy(() => import("../pages/auth/Login"));
const Register = lazy(() => import("../pages/auth/Register"));
const ResetPassword = lazy(() => import("../pages/auth/ResetPassword"));

// App
// - Profile
const ViewProfile = lazy(() => import("../pages/profile/ViewProfile"));
const EditProfile = lazy(() => import("../pages/profile/EditProfile"));
// - Partner
const PartnerProfile = lazy(
  () => import("../pages/profile/partner/PartnerProfile")
);
const CreatePartnerProfile = lazy(
  () => import("../pages/profile/partner/CreatePartnerProfile")
);
const EditPartnerProfile = lazy(
  () => import("../pages/profile/partner/EditPartnerProfile")
);
const CreatePartnerBranch = lazy(
  () => import("../pages/profile/partner/CreatePartnerBranch")
);
const EditPartnerBranch = lazy(
  () => import("../pages/profile/partner/EditPartnerBranch")
);
// Explore
const CafeDetails = lazy(() => import("../pages/explore/CafeDetails"));
const Explore = lazy(() => import("../pages/explore/ExploreView"));

// Admin
const EcopaAppConfig = lazy(() => import("../pages/admin/EcopaAppConfigView"));
const AdminUsersTable = lazy(() => import("../pages/admin/EcopaUsersTable"));
const AdminPartnersTable = lazy(
  () => import("../pages/admin/EcopaPartnersTable")
);
const AdminAdminsTable = lazy(
  () => import("../pages/admin/EcopaAdminUsersTable")
);

const appRoutes = [
  // Auth
  {
    layout: (
      <AuthGuard>
        <AppBarLayout />
      </AuthGuard>
    ),
    route: {
      path: "auth",
      element: <Navigate to={PATH_AUTH.login} replace />,
    },
    subpaths: [
      {
        path: "login",
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: "register",
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        ),
      },
      { path: "reset-password", element: <ResetPassword /> },
    ],
  },
  // Home Routes
  {
    layout: (
      <AuthGuard>
        <AppBarLayout />
      </AuthGuard>
    ),
    route: {
      path: "/",
      element: <Home />,
    },
  },
  // App Routes
  {
    layout: (
      <AuthGuard>
        <AppBarLayout />
      </AuthGuard>
    ),
    route: {
      path: "profile",
      element: <ViewProfile />,
    },
    subpaths: [
      {
        path: "me",
        element: <EditProfile />,
      },
      // Partner info
      {
        path: "partner",
        element: <PartnerProfile />,
      },
      {
        path: "partner/add",
        element: <CreatePartnerProfile />,
      },
      {
        path: "partner/edit",
        element: <EditPartnerProfile />,
      },
      // Partner Branches
      {
        path: "partner/branch/add",
        element: <CreatePartnerBranch />,
      },
      {
        path: "partner/branch/edit/:branchId",
        element: <EditPartnerBranch />,
      },
    ],
  },
  {
    layout: (
      <AuthGuard>
        <AppBarLayout />
      </AuthGuard>
    ),
    route: {
      path: "explore",
      element: <Navigate to={PATH_APP.explore.map} />,
    },
    subpaths: [
      // Explore
      {
        path: "map",
        element: <Explore viewType="map" />,
      },
      {
        path: "search",
        element: <Explore viewType="search" />,
      },
      {
        path: "cafe/:branchId",
        element: <CafeDetails />,
      },
    ],
  },
  {
    layout: (
      <AuthGuard>
        <AppBarLayout />
      </AuthGuard>
    ),
    route: {
      path: "admin",
      element: <Navigate to={PATH_APP.admin.config} />,
    },
    subpaths: [
      // Admin
      {
        path: "config",
        element: <EcopaAppConfig />,
      },
      {
        path: "users",
        element: <AdminUsersTable />,
      },
      {
        path: "partners",
        element: <AdminPartnersTable />,
      },
      {
        path: "admins",
        element: <AdminAdminsTable />,
      },
    ],
  },
  // Redirect Routes
  {
    route: {
      path: "*",
      element: <Navigate to={PATH_APP.root} replace />,
    },
  },
];

export { appRoutes };
export default Router;
