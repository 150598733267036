import { ecopaApiConfig } from "../config";
import { StripeCardType } from "../types/auxTypes";
import {
  EcopaPartner,
  EcopaPartnerBase,
  EcopaPartnerPlan,
  EcopaPlan,
} from "../types/ecopaPartner";
import { authedFetchGet, authedFetchPost } from "../utils/authedRequest";
import { convertErrorToString } from "../utils/errors";
import { ApiError } from "./apiError";

const ecopaPartnerEndpoints = {
  newPartner: ecopaApiConfig.host + "/partner/",
  getPartner: ecopaApiConfig.host + "/partner/",
  updatePartner: ecopaApiConfig.host + "/partner/",
  postStripeSecret: ecopaApiConfig.host + "/partner/setup-payment",
  getStripeCards: ecopaApiConfig.host + "/partner/get-cards",
  deleteStripeCard: ecopaApiConfig.host + "/partner/delete-card",
};

type EcopaPartnerApiBase = EcopaPartnerBase;
type EcopaPartnerApi = EcopaPartner & {
  plan: {
    ecopa_plan_id: string;
    ecopa_partner_id: string;
    stripe_id: string;
    stripe_subscription_id: string;
  };
};

/**
 *  Post a new Ecopa Partner
 * @param ecopaPartner
 * @param plans
 * @returns
 */
export async function postNewEcopaPartner(
  ecopaPartner: EcopaPartnerBase & { plan: EcopaPlan },
  plans: EcopaPlan[]
): Promise<{
  ecopaPartner?: EcopaPartnerPlan;
  error?: string;
}> {
  try {
    // post the new partner
    const respPartner: EcopaPartnerApi | ApiError = await authedFetchPost({
      endpoint: ecopaPartnerEndpoints.newPartner,
      data: ecopaPartner as EcopaPartnerApiBase,
    });

    if ("detail" in respPartner) {
      throw new Error(JSON.stringify(respPartner.detail));
    }

    // parse data
    const plan = plans.find((p) => p.id === respPartner.plan.ecopa_plan_id);
    const ePartner = {
      ...respPartner,
      plan,
    } as EcopaPartnerPlan;

    // return data
    return {
      ecopaPartner: ePartner,
    };
  } catch (error) {
    console.error("Error in postNewEcopaPartner:", error);
    return { error: convertErrorToString(error) };
  }
}

/**
 *  Get the Ecopa Partner
 * @param plans
 * @returns
 */
export async function getEcopaPartner(plans: EcopaPlan[]): Promise<{
  ecopaPartner?: EcopaPartnerPlan;
  error?: string;
}> {
  try {
    // post the new partner
    const respPartner: EcopaPartnerApi | ApiError = await authedFetchGet({
      endpoint: ecopaPartnerEndpoints.getPartner,
    });

    if ("detail" in respPartner) {
      throw new Error(JSON.stringify(respPartner.detail));
    }

    // parse data
    const plan = plans.find((p) => p.id === respPartner.plan.ecopa_plan_id);
    const ePartner = {
      ...respPartner,
      plan: {
        ...plan,
        stripe_id: respPartner.plan.stripe_id,
        stripe_subscription_id: respPartner.plan.stripe_subscription_id,
      },
    } as EcopaPartnerPlan;

    // return data
    return {
      ecopaPartner: ePartner,
    };
  } catch (error) {
    console.error("Error in getEcopaPartner:", error);
    return { error: convertErrorToString(error) };
  }
}

/**
 *  Update the Ecopa Partner
 * @param ecopaPartner
 * @param plans
 * @returns
 */
export async function putUpdateEcopaPartner(
  ecopaPartner: EcopaPartner & { plan: EcopaPlan },
  plans: EcopaPlan[]
): Promise<{
  ecopaPartner?: EcopaPartnerPlan;
  error?: string;
}> {
  try {
    // post the new partner
    const respPartner: EcopaPartnerApi | ApiError = await authedFetchPost({
      endpoint: ecopaPartnerEndpoints.updatePartner,
      data: ecopaPartner as EcopaPartner & { plan: EcopaPlan },
      httpMethod: "PUT",
    });

    if ("detail" in respPartner) {
      throw new Error(JSON.stringify(respPartner.detail));
    }

    // parse data
    const plan = plans.find((p) => p.id === respPartner.plan.ecopa_plan_id);
    const ePartner = {
      ...respPartner,
      plan,
    } as EcopaPartnerPlan;

    // return data
    return {
      ecopaPartner: ePartner,
    };
  } catch (error) {
    console.error("Error in putUpdateEcopaPartner:", error);
    return { error: convertErrorToString(error) };
  }
}

// Stripe
interface EcopaStripeResponse {
  status: boolean;
  msg: string;
  data?: string;
}

/**
 *  Get the Ecopa Stripe Secret
 * @param email
 * @returns
 */
export async function getEcopaStripeSecret(email: string): Promise<{
  ecopaSecret?: { secret: string };
  error?: string;
}> {
  try {
    // get the stripe secret
    const respPartner: { secret: string } | ApiError = await authedFetchPost({
      endpoint: ecopaPartnerEndpoints.postStripeSecret,
      data: {
        email,
      },
    });

    if ("detail" in respPartner) {
      throw new Error(JSON.stringify(respPartner.detail));
    }

    // return data
    return {
      ecopaSecret: respPartner,
    };
  } catch (error) {
    console.error("Error in getEcopaStripeSecret:", error);
    return { error: convertErrorToString(error) };
  }
}

/**
 *  Delete the Ecopa Stripe Card
 * @param email
 * @returns
 */
export async function deleteEcopaStripeCard(
  customerId: string,
  cardId: string
): Promise<{
  gotDeleted?: boolean;
  error?: string;
}> {
  try {
    if (!customerId || !cardId) {
      throw new Error("Invalid customerId or cardId");
    }
    // delete the stripe card
    const respPartner: EcopaStripeResponse | ApiError = await authedFetchPost({
      endpoint: ecopaPartnerEndpoints.deleteStripeCard,
      data: {
        stripe_customer_id: customerId,
        stripe_card_id: cardId,
      },
    });

    if ("detail" in respPartner) {
      throw new Error(JSON.stringify(respPartner.detail));
    }

    // return data
    return {
      gotDeleted: respPartner.status,
    };
  } catch (error) {
    console.error("Error in getEcopaStripeSecret:", error);
    return { error: convertErrorToString(error) };
  }
}

/**
 *  Get the Ecopa Stripe Cards
 * @param email
 * @returns
 */
export async function getEcopaStripeCards(email: string): Promise<{
  cards?: StripeCardType[];
  error?: string;
}> {
  try {
    // get stripe cards
    const respPartner: EcopaStripeResponse | ApiError = await authedFetchGet({
      endpoint: ecopaPartnerEndpoints.getStripeCards,
      data: {
        email,
      },
    });

    if ("detail" in respPartner) {
      throw new Error(JSON.stringify(respPartner.detail));
    }

    const cards = JSON.parse(respPartner?.data || "[]").map((c: any) => ({
      id: c.id,
      nameInCard: c?.billing_details?.name || "*****",
      cardBrand: c.card.brand,
      cardLast4: c.card.last4,
      isDefault: false,
    }));

    // return data
    return {
      cards,
    };
  } catch (error) {
    console.error("Error in getEcopaStripeCards:", error);
    return { error: convertErrorToString(error) };
  }
}
