export const countryCodes = [
  { country: "Afghanistan", phoneCode: "93" },
  { country: "Albania", phoneCode: "355" },
  { country: "Algeria", phoneCode: "213" },
  { country: "American Samoa", phoneCode: "1-684" },
  { country: "Andorra", phoneCode: "376" },
  { country: "Angola", phoneCode: "244" },
  { country: "Anguilla", phoneCode: "1-264" },
  { country: "Antarctica", phoneCode: "672" },
  { country: "Antigua and Barbuda", phoneCode: "1-268" },
  { country: "Argentina", phoneCode: "54" },
  { country: "Armenia", phoneCode: "374" },
  { country: "Aruba", phoneCode: "297" },
  { country: "Austria", phoneCode: "43" },
  { country: "Azerbaijan", phoneCode: "994" },
  { country: "Bahamas", phoneCode: "1-242" },
  { country: "Bahrain", phoneCode: "973" },
  { country: "Bangladesh", phoneCode: "880" },
  { country: "Barbados", phoneCode: "1-246" },
  { country: "Belarus", phoneCode: "375" },
  { country: "Belgium", phoneCode: "32" },
  { country: "Belize", phoneCode: "501" },
  { country: "Benin", phoneCode: "229" },
  { country: "Bermuda", phoneCode: "1-441" },
  { country: "Bhutan", phoneCode: "975" },
  { country: "Bolivia", phoneCode: "591" },
  { country: "Bosnia and Herzegovina", phoneCode: "387" },
  { country: "Botswana", phoneCode: "267" },
  { country: "Brazil", phoneCode: "55" },
  { country: "British Indian Ocean Territory", phoneCode: "246" },
  { country: "British Virgin Islands", phoneCode: "1-284" },
  { country: "Brunei", phoneCode: "673" },
  { country: "Bulgaria", phoneCode: "359" },
  { country: "Burkina Faso", phoneCode: "226" },
  { country: "Burundi", phoneCode: "257" },
  { country: "Cambodia", phoneCode: "855" },
  { country: "Cameroon", phoneCode: "237" },
  { country: "Cape Verde", phoneCode: "238" },
  { country: "Cayman Islands", phoneCode: "1-345" },
  { country: "Central African Republic", phoneCode: "236" },
  { country: "Chad", phoneCode: "235" },
  { country: "Chile", phoneCode: "56" },
  { country: "China", phoneCode: "86" },
  { country: "Australia", phoneCode: "61" },
  { country: "Colombia", phoneCode: "57" },
  { country: "Comoros", phoneCode: "269" },
  { country: "Cook Islands", phoneCode: "682" },
  { country: "Costa Rica", phoneCode: "506" },
  { country: "Croatia", phoneCode: "385" },
  { country: "Cuba", phoneCode: "53" },
  { country: "Curacao", phoneCode: "599" },
  { country: "Cyprus", phoneCode: "357" },
  { country: "Czech Republic", phoneCode: "420" },
  { country: "Democratic Republic of the Congo", phoneCode: "243" },
  { country: "Denmark", phoneCode: "45" },
  { country: "Djibouti", phoneCode: "253" },
  { country: "Dominica", phoneCode: "1-767" },
  { country: "Dominican Republic", phoneCode: "1-809" },
  { country: "East Timor", phoneCode: "670" },
  { country: "Ecuador", phoneCode: "593" },
  { country: "Egypt", phoneCode: "20" },
  { country: "El Salvador", phoneCode: "503" },
  { country: "Equatorial Guinea", phoneCode: "240" },
  { country: "Eritrea", phoneCode: "291" },
  { country: "Estonia", phoneCode: "372" },
  { country: "Ethiopia", phoneCode: "251" },
  { country: "Falkland Islands", phoneCode: "500" },
  { country: "Faroe Islands", phoneCode: "298" },
  { country: "Fiji", phoneCode: "679" },
  { country: "Finland", phoneCode: "358" },
  { country: "France", phoneCode: "33" },
  { country: "French Polynesia", phoneCode: "689" },
  { country: "Gabon", phoneCode: "241" },
  { country: "Gambia", phoneCode: "220" },
  { country: "Georgia", phoneCode: "995" },
  { country: "Germany", phoneCode: "49" },
  { country: "Ghana", phoneCode: "233" },
  { country: "Gibraltar", phoneCode: "350" },
  { country: "Greece", phoneCode: "30" },
  { country: "Greenland", phoneCode: "299" },
  { country: "Grenada", phoneCode: "1-473" },
  { country: "Guam", phoneCode: "1-671" },
  { country: "Guatemala", phoneCode: "502" },
  { country: "Guernsey", phoneCode: "44-1481" },
  { country: "Guinea", phoneCode: "224" },
  { country: "Guinea-Bissau", phoneCode: "245" },
  { country: "Guyana", phoneCode: "592" },
  { country: "Haiti", phoneCode: "509" },
  { country: "Honduras", phoneCode: "504" },
  { country: "Hong Kong", phoneCode: "852" },
  { country: "Hungary", phoneCode: "36" },
  { country: "Iceland", phoneCode: "354" },
  { country: "India", phoneCode: "91" },
  { country: "Indonesia", phoneCode: "62" },
  { country: "Iran", phoneCode: "98" },
  { country: "Iraq", phoneCode: "964" },
  { country: "Ireland", phoneCode: "353" },
  { country: "Isle of Man", phoneCode: "44-1624" },
  { country: "Israel", phoneCode: "972" },
  { country: "Italy", phoneCode: "39" },
  { country: "Ivory Coast", phoneCode: "225" },
  { country: "Jamaica", phoneCode: "1-876" },
  { country: "Japan", phoneCode: "81" },
  { country: "Jersey", phoneCode: "44-1534" },
  { country: "Jordan", phoneCode: "962" },
  { country: "Kenya", phoneCode: "254" },
  { country: "Kiribati", phoneCode: "686" },
  { country: "Kosovo", phoneCode: "383" },
  { country: "Kuwait", phoneCode: "965" },
  { country: "Kyrgyzstan", phoneCode: "996" },
  { country: "Laos", phoneCode: "856" },
  { country: "Latvia", phoneCode: "371" },
  { country: "Lebanon", phoneCode: "961" },
  { country: "Lesotho", phoneCode: "266" },
  { country: "Liberia", phoneCode: "231" },
  { country: "Libya", phoneCode: "218" },
  { country: "Liechtenstein", phoneCode: "423" },
  { country: "Lithuania", phoneCode: "370" },
  { country: "Luxembourg", phoneCode: "352" },
  { country: "Macau", phoneCode: "853" },
  { country: "Macedonia", phoneCode: "389" },
  { country: "Madagascar", phoneCode: "261" },
  { country: "Malawi", phoneCode: "265" },
  { country: "Malaysia", phoneCode: "60" },
  { country: "Maldives", phoneCode: "960" },
  { country: "Mali", phoneCode: "223" },
  { country: "Malta", phoneCode: "356" },
  { country: "Marshall Islands", phoneCode: "692" },
  { country: "Mauritania", phoneCode: "222" },
  { country: "Mauritius", phoneCode: "230" },
  { country: "Mayotte", phoneCode: "262" },
  { country: "Mexico", phoneCode: "52" },
  { country: "Micronesia", phoneCode: "691" },
  { country: "Moldova", phoneCode: "373" },
  { country: "Monaco", phoneCode: "377" },
  { country: "Mongolia", phoneCode: "976" },
  { country: "Montenegro", phoneCode: "382" },
  { country: "Montserrat", phoneCode: "1-664" },
  { country: "Morocco", phoneCode: "212" },
  { country: "Mozambique", phoneCode: "258" },
  { country: "Myanmar", phoneCode: "95" },
  { country: "Namibia", phoneCode: "264" },
  { country: "Nauru", phoneCode: "674" },
  { country: "Nepal", phoneCode: "977" },
  { country: "Netherlands", phoneCode: "31" },
  { country: "New Caledonia", phoneCode: "687" },
  { country: "New Zealand", phoneCode: "64" },
  { country: "Nicaragua", phoneCode: "505" },
  { country: "Niger", phoneCode: "227" },
  { country: "Nigeria", phoneCode: "234" },
  { country: "Niue", phoneCode: "683" },
  { country: "North Korea", phoneCode: "850" },
  { country: "Northern Mariana Islands", phoneCode: "1-670" },
  { country: "Norway", phoneCode: "47" },
  { country: "Oman", phoneCode: "968" },
  { country: "Pakistan", phoneCode: "92" },
  { country: "Palau", phoneCode: "680" },
  { country: "Palestine", phoneCode: "970" },
  { country: "Panama", phoneCode: "507" },
  { country: "Papua New Guinea", phoneCode: "675" },
  { country: "Paraguay", phoneCode: "595" },
  { country: "Peru", phoneCode: "51" },
  { country: "Philippines", phoneCode: "63" },
  { country: "Poland", phoneCode: "48" },
  { country: "Portugal", phoneCode: "351" },
  { country: "Puerto Rico", phoneCode: "1-939" },
  { country: "Qatar", phoneCode: "974" },
  { country: "Republic of the Congo", phoneCode: "242" },
  { country: "Romania", phoneCode: "40" },
  { country: "Russia", phoneCode: "7" },
  { country: "Rwanda", phoneCode: "250" },
  { country: "Saint Helena", phoneCode: "290" },
  { country: "Saint Kitts and Nevis", phoneCode: "1-869" },
  { country: "Saint Lucia", phoneCode: "1-758" },
  { country: "Saint Martin", phoneCode: "590" },
  { country: "Saint Pierre and Miquelon", phoneCode: "508" },
  { country: "Saint Vincent and the Grenadines", phoneCode: "1-784" },
  { country: "Samoa", phoneCode: "685" },
  { country: "San Marino", phoneCode: "378" },
  { country: "Sao Tome and Principe", phoneCode: "239" },
  { country: "Saudi Arabia", phoneCode: "966" },
  { country: "Senegal", phoneCode: "221" },
  { country: "Serbia", phoneCode: "381" },
  { country: "Seychelles", phoneCode: "248" },
  { country: "Sierra Leone", phoneCode: "232" },
  { country: "Singapore", phoneCode: "65" },
  { country: "Sint Maarten", phoneCode: "1-721" },
  { country: "Slovakia", phoneCode: "421" },
  { country: "Slovenia", phoneCode: "386" },
  { country: "Solomon Islands", phoneCode: "677" },
  { country: "Somalia", phoneCode: "252" },
  { country: "South Africa", phoneCode: "27" },
  { country: "South Korea", phoneCode: "82" },
  { country: "South Sudan", phoneCode: "211" },
  { country: "Spain", phoneCode: "34" },
  { country: "Sri Lanka", phoneCode: "94" },
  { country: "Sudan", phoneCode: "249" },
  { country: "Suriname", phoneCode: "597" },
  { country: "Swaziland", phoneCode: "268" },
  { country: "Sweden", phoneCode: "46" },
  { country: "Switzerland", phoneCode: "41" },
  { country: "Syria", phoneCode: "963" },
  { country: "Taiwan", phoneCode: "886" },
  { country: "Tajikistan", phoneCode: "992" },
  { country: "Tanzania", phoneCode: "255" },
  { country: "Thailand", phoneCode: "66" },
  { country: "Togo", phoneCode: "228" },
  { country: "Tokelau", phoneCode: "690" },
  { country: "Tonga", phoneCode: "676" },
  { country: "Trinidad and Tobago", phoneCode: "1-868" },
  { country: "Tunisia", phoneCode: "216" },
  { country: "Turkey", phoneCode: "90" },
  { country: "Turkmenistan", phoneCode: "993" },
  { country: "Turks and Caicos Islands", phoneCode: "1-649" },
  { country: "Tuvalu", phoneCode: "688" },
  { country: "U.S. Virgin Islands", phoneCode: "1-340" },
  { country: "Uganda", phoneCode: "256" },
  { country: "Ukraine", phoneCode: "380" },
  { country: "United Arab Emirates", phoneCode: "971" },
  { country: "United Kingdom", phoneCode: "44" },
  { country: "United States / Canada", phoneCode: "1" },
  { country: "Uruguay", phoneCode: "598" },
  { country: "Uzbekistan", phoneCode: "998" },
  { country: "Vanuatu", phoneCode: "678" },
  { country: "Vatican", phoneCode: "379" },
  { country: "Venezuela", phoneCode: "58" },
  { country: "Vietnam", phoneCode: "84" },
  { country: "Wallis and Futuna", phoneCode: "681" },
  { country: "Yemen", phoneCode: "967" },
  { country: "Zambia", phoneCode: "260" },
  { country: "Zimbabwe", phoneCode: "263" },
];
