import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Link as RouterLink } from "react-router-dom";
import "firebase/compat/auth";
// material
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Typography,
  IconButton,
  Grid,
  TextField,
  Link,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
// hooks
import useAuth from "../../hooks/useAuth";
// components
import Page from "../../components/Page";
import MHidden from "../../components/extensions/MHidden";
import { RectangleLogo } from "../../components/Logo";
import { useSnackbar } from "notistack";
import LoadingProgress from "../../components/LoadingProgress";
import { countryCodes } from "../../utils/countryCodes";
import { Form, FormikProvider, useFormik } from "formik";
import { putUpdateEcopaUser } from "../../api/ecopaUser";
// utils

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  height: "100%",
  width: "100%",
}));

// ----------------------------------------------------------------------

type PhoneSelectorProps = {
  onPhoneReady: (phone: string, countryCode: string, fullPhone: string) => void;
};

const PhoneSelector: React.FC<PhoneSelectorProps> = ({ onPhoneReady }) => {
  const PhonePickSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .length(10, "¡Teléfono debe de ser a 10 dígitos")
      .matches(/\d*/)
      .required("Teléfono es requerido."),
    countryCode: Yup.string()
      .oneOf(countryCodes.map((c) => c.phoneCode))
      .required("CEDIS es requerida."),
  });

  const formik = useFormik({
    initialValues: {
      phoneNumber: "",
      countryCode: "52",
    },
    validationSchema: PhonePickSchema,
    onSubmit: async (values, { setSubmitting }) => {
      onPhoneReady(
        values.phoneNumber,
        values.countryCode,
        values.countryCode + values.phoneNumber
      );
      setSubmitting(false);
    },
  });

  const { values, errors, touched, getFieldProps } = formik;

  useEffect(() => {
    if (errors.phoneNumber) {
      formik.setFieldError("phoneNumber", errors.phoneNumber);
      onPhoneReady("", "", "");
    } else if (errors.countryCode) {
      formik.setFieldError("countryCode", errors.countryCode);
      onPhoneReady("", "", "");
    } else if (values.phoneNumber !== "" && values.countryCode !== "") {
      onPhoneReady(
        values.phoneNumber,
        values.countryCode,
        `${values.countryCode}${values.phoneNumber}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, errors]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate>
        <Grid container>
          <Grid item xs={3}>
            <TextField
              select
              fullWidth
              label=""
              {...getFieldProps("countryCode")}
              error={Boolean(touched.countryCode && errors.countryCode)}
              helperText={touched.countryCode && errors.countryCode}
              SelectProps={{
                native: true,
              }}
            >
              {countryCodes.map((cc) => (
                <option key={cc.phoneCode} value={cc.phoneCode}>
                  +{cc.phoneCode}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={9} pl={1}>
            <TextField
              fullWidth
              autoComplete="phone"
              type="number"
              label="Teléfono"
              {...getFieldProps("phoneNumber")}
              error={Boolean(touched.phoneNumber && errors.phoneNumber)}
              helperText={touched.phoneNumber && errors.phoneNumber}
            />
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
};

// ----------------------------------------------------------------------

export default function Verification() {
  // hooks
  const {
    user,
    isInitialized,
    sendPhoneNumberValidation,
    verifyPhoneNumberCode,
    verification,
  } = useAuth();
  const theme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // states
  // const validationMethod = "sms";
  const [isSubmitting, setSubmitting] = useState(false);
  const [isCodeSent, setCodeSent] = useState(false);
  const [valCode, setValCode] = useState<string>("");
  const [isConfirming, setConfirming] = useState(false);
  const [revCounter, setRevCounter] = useState(0);
  // phone
  const [newPhone, setNewPhone] = useState<string>("");
  const [newCcode, setNewccode] = useState<string>("");
  const [fullPhone, setFullPhone] = useState<string>(
    `${user?.countryCode}${user?.phoneNumber}`
  );

  useEffect(() => {
    if (user?.phoneNumber && user?.countryCode) {
      setFullPhone(`${user.countryCode}${user.phoneNumber}`);
    }
  }, [user]);

  const handleValCodeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    let tmpstr = event.target.value.replaceAll("-", "");
    let dipstr = "";
    for (let j = 0; j < tmpstr.length; j += 1) {
      let dig = tmpstr[j];
      if (!dig.match(/\d+/)) {
        continue;
      }
      if (j === 3) {
        dipstr += "-";
      }
      if (j < 6) {
        dipstr += tmpstr[j];
      }
    }
    setValCode(dipstr);
  };

  const sendCode = async () => {
    setSubmitting(true);
    setRevCounter(60); // resend message in 30 secs
    if (fullPhone) {
      await sendPhoneNumberValidation("recaptcha-container", "+" + fullPhone);
      setCodeSent(true);
      // update phone number
      if (user) {
        if (!user.phoneNumber && !user.countryCode) {
          const updUsr = await putUpdateEcopaUser({
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            countryCode: newCcode.toString(),
            phoneNumber: newPhone.toString(),
          });
          if (updUsr.error) {
            enqueueSnackbar(
              `Error actualizando tu número de teléfono. ${updUsr.error}`,
              {
                variant: "error",
                autoHideDuration: 2000,
                action: (key) => (
                  <IconButton size="small" onClick={() => closeSnackbar(key)}>
                    <Icon icon={closeFill} />
                  </IconButton>
                ),
              }
            );
          }
        }
      }
    } else {
      alert("No hay número de teléfono para enviar el código.");
    }
    // send message
    enqueueSnackbar(`Enviando código por SMS`, {
      variant: "success",
      //   autoHideDuration: 1000,
      action: (key) => (
        <IconButton size="small" onClick={() => closeSnackbar(key)}>
          <Icon icon={closeFill} />
        </IconButton>
      ),
    });
    setSubmitting(false);
  };

  const confirmCode = async () => {
    setConfirming(true);
    verifyPhoneNumberCode(valCode.replaceAll("-", ""));
  };

  const validationObj = "código";

  useEffect(() => {
    // Validation of errors
    if (verification.error === "sms_sending_phone_validation_issue") {
      enqueueSnackbar(
        `Error enviando el código por SMS. Intenta con otro método de validación.`,
        {
          variant: "error",
          autoHideDuration: 2000,
          action: (key) => (
            <IconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </IconButton>
          ),
        }
      );
      setConfirming(false);
    } else if (verification.error === "sms_verifying_phone_validation_issue") {
      enqueueSnackbar(
        `Error en tu código de validación. Por favor revísalo de nuevo.`,
        {
          variant: "error",
          autoHideDuration: 2000,
          action: (key) => (
            <IconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </IconButton>
          ),
        }
      );
      setValCode(""); // reset validation code to retry
      setConfirming(false);
    } else if (verification.error === "email_verifying_validation_issue") {
      enqueueSnackbar(
        `Error enviando el código por Email. Intenta con otro método de validación .`,
        {
          variant: "error",
          autoHideDuration: 2000,
          action: (key) => (
            <IconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </IconButton>
          ),
        }
      );
      setConfirming(false);
    } else if (verification.isVerified && verification.method === "sms") {
      enqueueSnackbar(
        `Tu código de validación ha sido correctamente verificado.`,
        {
          variant: "success",
          autoHideDuration: 2000,
          action: (key) => (
            <IconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </IconButton>
          ),
        }
      );
      setValCode(""); // reset validation code to retry
      setConfirming(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verification]);

  // Reverse counter to resend code.
  setTimeout(() => {
    if (revCounter > 0) {
      setRevCounter(revCounter - 1);
    }
  }, 1000);

  if (!isInitialized) {
    return <LoadingProgress sx={{ height: "70vh" }} />;
  }
  return (
    <RootStyle title="Verificación de Usuario | Ecopa">
      <MHidden width="mdDown">
        <Box
          component={RouterLink}
          to="/"
          sx={{ position: "absolute", top: 4, left: "20%" }}
        >
          <RectangleLogo width={200} />
        </Box>
      </MHidden>

      <Grid
        container
        sx={{
          mt: { xs: 8, md: 16 },
          mb: { xs: 0, md: 0 },
          minHeight: "70vh",
        }}
      >
        <Grid item xs={0} md={4} />
        <Grid item xs={12} md={4} sx={{ px: 2 }}>
          {/* Instructions */}
          <Box sx={{ flexGrow: 1, mb: 2 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                Verificación
              </Typography>
              {/* Message for email login */}
              {user?.phoneNumber && (
                <Typography variant="body1" sx={{ color: "text.secondary" }}>
                  Recibirás un código de verificación de 6 dígitos por SMS al
                  número +{fullPhone}.
                </Typography>
              )}
              {/* Message for Google login */}
              {!user?.phoneNumber && (
                <Typography variant="body1" sx={{ color: "text.secondary" }}>
                  Necesitamos recibir un código de verificación de 6 dígitos por
                  SMS a tu teléfono, por favor escribe el número donde quieres
                  que se envíe.
                </Typography>
              )}
            </Box>
          </Box>

          {/* Firebase recaptcha */}
          <div id="recaptcha-container"></div>

          {/* Send code buttons */}
          {!isCodeSent && (
            <Box>
              {/* Phone number - in case it was Google registered*/}
              {user?.phoneNumber === "" && (
                <Box sx={{ mb: 2 }}>
                  <PhoneSelector
                    onPhoneReady={(ph, cc, fullPh) => {
                      setFullPhone(fullPh);
                      setNewPhone(ph);
                      setNewccode(cc);
                    }}
                  />
                </Box>
              )}
              <LoadingButton
                fullWidth
                disabled={fullPhone === ""}
                size="large"
                variant="contained"
                loading={isSubmitting}
                onClick={sendCode}
              >
                Enviar {validationObj}
              </LoadingButton>
            </Box>
          )}
          {/* Code validation components */}
          {isCodeSent && (
            <Box>
              <Grid container>
                <Grid item>
                  <Typography variant="body1" sx={{ color: "text.secondary" }}>
                    Cuando lo recibas, cópialo y pegalo aquí:
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  textAlign={"center"}
                  sx={{ mt: theme.spacing(2) }}
                >
                  <TextField
                    type="text"
                    value={valCode}
                    onChange={handleValCodeInput}
                    placeholder="000-000"
                    inputProps={{
                      style: {
                        textAlign: "center",
                        maxWidth: theme.spacing(12),
                        fontSize: theme.typography.h4.fontSize,
                      },
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container direction={"row"} sx={{ mt: theme.spacing(2) }}>
                <Grid item xs={12} sx={{ px: theme.spacing(1) }}>
                  <LoadingButton
                    fullWidth
                    disabled={valCode.length < 7}
                    size="large"
                    color="primary"
                    variant="contained"
                    loading={isConfirming}
                    onClick={confirmCode}
                  >
                    Continuar
                  </LoadingButton>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mt: theme.spacing(3) }}>
                <Grid item xs={12} lg={12}>
                  <Typography
                    variant="body2"
                    sx={{ color: "text.secondary", cursor: "pointer" }}
                  >
                    ¿No recibiste tu {validationObj}?&nbsp;
                    {revCounter === 0 && (
                      <Link variant="subtitle1" onClick={sendCode}>
                        Reenviar {validationObj}
                      </Link>
                    )}
                    {revCounter > 0 && (
                      <Link
                        variant="subtitle1"
                        color={theme.palette.action.disabled}
                      >
                        Reenvíalo en {revCounter} segs.
                      </Link>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
      </Grid>
    </RootStyle>
  );
}
