import { Outlet } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
// hooks
// components
import BottomAppBar from "./AppBar";

// ----------------------------------------------------------------------

// const APP_BAR_MOBILE = 64;
// const APP_BAR_DESKTOP = 92;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

// ----------------------------------------------------------------------

export default function AppBarLayout() {
  return (
    <RootStyle>
      <Outlet />
      <BottomAppBar />
    </RootStyle>
  );
}
