import Cookies from "js-cookie";

interface ApiFetchProps {
  endpoint: string;
  httpMethod?: "GET" | "POST" | "PUT" | "DELETE";
  headers?: any;
  data?: any;
}

export const authedFetchGet = async ({
  endpoint,
  headers,
  httpMethod = "GET",
  data,
}: ApiFetchProps): Promise<any> => {
  // headers
  const apiHeads = new Headers();
  apiHeads.append("Content-Type", "application/json");
  apiHeads.append("Accept", "application/json");
  if (headers) {
    Object.keys(headers).forEach((key) => {
      apiHeads.append(key, headers[key]);
    });
  }
  // verify if token is in cookies
  const authToken = Cookies.get("token");
  if (authToken) {
    if (!apiHeads.has("Authorization")) {
      apiHeads.append("Authorization", `${authToken}`);
    }
  }
  // add data as query
  if (data) {
    const params = new URLSearchParams(data).toString();
    endpoint = `${endpoint}?${params}`;
  }

  // fetch
  try {
    const response = await fetch(endpoint, {
      method: httpMethod,
      headers: apiHeads,
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error in fetch:", error);
    return { error: error };
  }
};

export const authedFetchPost = async ({
  endpoint,
  headers,
  httpMethod = "POST",
  data,
}: ApiFetchProps): Promise<any> => {
  // headers
  const apiHeads = new Headers();
  apiHeads.append("Content-Type", "application/json");
  apiHeads.append("Accept", "application/json");
  if (headers) {
    Object.keys(headers).forEach((key) => {
      apiHeads.append(key, headers[key]);
    });
  }
  // verify if token is in cookies
  const authToken = Cookies.get("token");
  if (authToken) {
    apiHeads.append("Authorization", `${authToken}`);
  }

  // fetch
  try {
    const response = await fetch(endpoint, {
      method: httpMethod,
      headers: apiHeads,
      body: JSON.stringify(data),
    });

    const rData = await response.json();
    return rData;
  } catch (error) {
    console.error("Error in fetch:", error);
    return { error: error };
  }
};

export const authedFetchPostWithFiles = async ({
  endpoint,
  headers,
  httpMethod = "POST",
  data,
}: ApiFetchProps): Promise<any> => {
  // headers
  const apiHeads = new Headers();
  apiHeads.append("Accept", "application/json");
  if (headers) {
    Object.keys(headers).forEach((key) => {
      apiHeads.append(key, headers[key]);
    });
  }
  // verify if token is in cookies
  const authToken = Cookies.get("token");
  if (authToken) {
    apiHeads.append("Authorization", `${authToken}`);
  }

  // fetch
  try {
    const response = await fetch(endpoint, {
      method: httpMethod,
      headers: apiHeads,
      body: data,
    });

    const rData = await response.json();
    return rData;
  } catch (error) {
    console.error("Error in fetch:", error);
    return { error: error };
  }
};
