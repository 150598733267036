import { ecopaApiConfig } from "../config";
import { EcopaConfig, EcopaFeature, EcopaNews } from "../types/ecopaConfig";
import { EcopaPlan } from "../types/ecopaPartner";
import { authedFetchGet, authedFetchPost } from "../utils/authedRequest";
import { convertErrorToString } from "../utils/errors";
import { ApiError } from "./apiError";

const appConfigEndpoints = {
  config: ecopaApiConfig.host + "/config/",
  updateConfig: ecopaApiConfig.host + "/config/",
  news: ecopaApiConfig.host + "/config/news/",
  addNewsArticle: ecopaApiConfig.host + "/config/news/",
  deleteNewsArticle: ecopaApiConfig.host + "/config/news/",
  plans: ecopaApiConfig.host + "/config/plans/",
  features: ecopaApiConfig.host + "/config/features/",
};

export async function getEcopaConfig(): Promise<{
  config: EcopaConfig[];
  news: EcopaNews[];
  plans: EcopaPlan[];
  features: EcopaFeature[];
  error?: string;
}> {
  try {
    // fetch the config & news in parallel
    const [config, news, plans, features] = await Promise.all([
      authedFetchGet({ endpoint: appConfigEndpoints.config }),
      authedFetchGet({ endpoint: appConfigEndpoints.news }),
      authedFetchGet({ endpoint: appConfigEndpoints.plans }),
      authedFetchGet({ endpoint: appConfigEndpoints.features }),
    ]);

    // parse data
    const configData: EcopaConfig[] = config.map(
      (ci: any) => ({ ...ci } as EcopaConfig)
    );

    const newsData: EcopaNews[] = news.map(
      (ni: any) => ({ ...ni } as EcopaNews)
    );

    const plansData: EcopaPlan[] = plans.map(
      (pi: any) => ({ ...pi } as EcopaPlan)
    );

    const featuresData: EcopaFeature[] = features.map(
      (fi: any) => ({ ...fi } as EcopaFeature)
    );

    // return data
    return {
      config: configData,
      news: newsData,
      plans: plansData,
      features: featuresData,
    };
  } catch (error) {
    console.error("Error in getEcopaConfig:", error);
    return {
      config: [],
      news: [],
      plans: [],
      features: [],
      error: convertErrorToString(error),
    };
  }
}

export async function updateEcopaConfig(
  configKey: string,
  configValue: string
): Promise<{ error?: string; success?: boolean }> {
  try {
    const resp: { status: boolean; msg: string } | ApiError =
      await authedFetchPost({
        endpoint: appConfigEndpoints.updateConfig,
        httpMethod: "PUT",
        data: { config_key: configKey, config_value: configValue },
      });

    if ("detail" in resp) {
      throw new Error(JSON.stringify(resp.detail));
    }
    return {
      success: resp.status,
      error: resp.status ? undefined : resp.msg,
    };
  } catch (error) {
    console.error("Error in updateEcopaConfig:", error);
    return { error: convertErrorToString(error) };
  }
}

export async function addEcopaArticle(
  title: string,
  header_image_url: string,
  summary: string,
  blog_url: string
): Promise<{ error?: string; success?: boolean }> {
  try {
    const resp: { status: boolean; msg: string } | ApiError =
      await authedFetchPost({
        endpoint: appConfigEndpoints.addNewsArticle,
        data: {
          title,
          header_image_url,
          summary,
          blog_url,
        },
      });

    if ("detail" in resp) {
      throw new Error(JSON.stringify(resp.detail));
    }
    return {
      success: resp.status,
      error: resp.status ? undefined : resp.msg,
    };
  } catch (error) {
    console.error("Error in addEcopaArticle:", error);
    return { error: convertErrorToString(error) };
  }
}

export async function deleteEcopaArticle(
  id: number
): Promise<{ error?: string; success?: boolean }> {
  try {
    const resp: { status: boolean; msg: string } | ApiError =
      await authedFetchPost({
        endpoint: appConfigEndpoints.deleteNewsArticle + `?news_id=${id}`,
        httpMethod: "DELETE",
      });

    if ("detail" in resp) {
      throw new Error(JSON.stringify(resp.detail));
    }
    return {
      success: resp.status,
      error: resp.status ? undefined : resp.msg,
    };
  } catch (error) {
    console.error("Error in deleteEcopaArticle:", error);
    return { error: convertErrorToString(error) };
  }
}
