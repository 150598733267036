// material
import { styled } from "@mui/material";
import React from "react";

// ----------------------------------------------------------------------

type ImgLogoProps = {
  width?: number;
  height?: number;
  sx?: Object;
};

const ImgLogoStyle = styled("img")(({ theme }) => ({}));

const ImagoType: React.FC<ImgLogoProps> = ({
  width = 200,
  height = 200,
  sx = { borderRadius: "50%" },
}) => {
  return (
    <ImgLogoStyle
      src="/static/assets/ecopa-imagotype.png"
      height={height}
      width={width}
      sx={sx}
    />
  );
};

export default ImagoType;
