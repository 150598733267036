import { useReducer, createContext } from "react";
import type {
  EcopaBranchImage,
  EcopaBranchImageCreate,
  EcopaPartner,
  EcopaPartnerBase,
  EcopaPartnerBranch,
  EcopaPartnerBranchBase,
  EcopaPartnerBranchWithFeatures,
  EcopaPartnerPlan,
  EcopaPlan,
} from "../types/ecopaPartner";
import { EcopaFeature } from "../types/ecopaConfig";
import {
  getEcopaPartner,
  postNewEcopaPartner,
  putUpdateEcopaPartner,
} from "../api/ecopaPartner";
import { convertErrorToString } from "../utils/errors";
import {
  getEcopaPartnerBranches,
  postNewEcopaPartnerBranch,
  putUpdateEcopaPartnerBranch,
} from "../api/ecopaPartnerBranch";

// ----------------------------------------------------------------------

interface PartnerStateType {
  loading: boolean;
  loaded: boolean;
  error: string | null;
  partner?: EcopaPartnerPlan | undefined;
  branches: EcopaPartnerBranchWithFeatures[];
}

interface PartnerPayloadInterface {
  error?: string;
  partner?: EcopaPartnerPlan;
  branches?: EcopaPartnerBranchWithFeatures[];
}

const initialState: PartnerStateType = {
  loading: false,
  loaded: false,
  error: null,
  partner: undefined,
  branches: [],
};

const reducer = (
  state: PartnerStateType,
  action: {
    type: string;
    payload: PartnerPayloadInterface;
  }
) => {
  if (action.type === "LOADING") {
    return {
      ...state,
      loading: true,
    };
  } else if (action.type === "ERROR") {
    if (!action.payload.error) {
      return {
        ...state,
        loading: false,
        error: "Unexpected error occurred.",
      };
    }
    return {
      ...state,
      loading: false,
      error: action.payload.error,
    };
  } else if (action.type === "PARTNER_LOADED") {
    return {
      ...state,
      loading: false,
      loaded: true,
      partner: action.payload.partner,
    };
  } else if (action.type === "BRANCH_LOADED") {
    return {
      ...state,
      loading: false,
      loaded: true,
      branches: action.payload.branches || [],
    };
  }
  return state;
};

const partnerActions = {
  initialisePartner: (plans: EcopaPlan[], allFeatures: EcopaFeature[]) =>
    Promise.resolve(),
  createPartner: (
    partner: EcopaPartnerBase,
    plan: EcopaPlan,
    plans: EcopaPlan[]
  ) => Promise.resolve(),
  updatePartner: (partner: EcopaPartner, plans: EcopaPlan[], plan: EcopaPlan) =>
    Promise.resolve(),
  createBranch: (
    branch: EcopaPartnerBranchBase,
    images: EcopaBranchImageCreate[],
    features: EcopaFeature[],
    tags: string[],
    allFeatures: EcopaFeature[]
  ) => Promise.resolve(),
  updateBranch: (
    branch: EcopaPartnerBranch,
    allFeatures: EcopaFeature[],
    newImages: EcopaBranchImageCreate[],
    editImages: EcopaBranchImage[],
    features: EcopaFeature[],
    tags: string[]
  ) => Promise.resolve(),
};

const PartnerContext = createContext({
  ...initialState,
  ...partnerActions,
});

type PartnerProviderProps = {
  children?: any;
};

function PartnerProvider({ children }: PartnerProviderProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialisePartner = async (
    plans: EcopaPlan[],
    allFeatures: EcopaFeature[]
  ) => {
    try {
      dispatch({ type: "LOADING", payload: {} });
      // get the partner
      const responsePartner = await getEcopaPartner(plans);
      // get branches
      if (responsePartner.ecopaPartner) {
        const responseBranches = await getEcopaPartnerBranches(
          responsePartner.ecopaPartner.id,
          allFeatures
        );
        if (responseBranches.branches && responseBranches.branches.length > 0) {
          dispatch({
            type: "BRANCH_LOADED",
            payload: { branches: responseBranches.branches },
          });
        }
      }
      dispatch({
        type: "PARTNER_LOADED",
        payload: { partner: responsePartner.ecopaPartner },
      });
    } catch (error) {
      dispatch({
        type: "ERROR",
        payload: { error: convertErrorToString(error) },
      });
      throw error;
    }
  };

  const createPartner = async (
    partner: EcopaPartnerBase,
    plan: EcopaPlan,
    plans: EcopaPlan[]
  ) => {
    try {
      dispatch({ type: "LOADING", payload: {} });
      // post the new partner
      const partnerInp = {
        ...partner,
        plan,
      };
      const responsePartner = await postNewEcopaPartner(partnerInp, plans);
      if (responsePartner.error) {
        throw new Error(responsePartner.error);
      }
      dispatch({
        type: "PARTNER_LOADED",
        payload: { partner: responsePartner.ecopaPartner },
      });
    } catch (error) {
      dispatch({
        type: "ERROR",
        payload: { error: convertErrorToString(error) },
      });
      throw error;
    }
  };

  const updatePartner = async (
    partner: EcopaPartner,
    plans: EcopaPlan[],
    plan: EcopaPlan
  ) => {
    try {
      dispatch({ type: "LOADING", payload: {} });
      // post the new partner
      const partnerInp = {
        ...partner,
        plan,
      };
      const responsePartner = await putUpdateEcopaPartner(partnerInp, plans);
      if (responsePartner.error) {
        throw new Error(responsePartner.error);
      }
      dispatch({
        type: "PARTNER_LOADED",
        payload: { partner: responsePartner.ecopaPartner },
      });
    } catch (error) {
      dispatch({
        type: "ERROR",
        payload: { error: convertErrorToString(error) },
      });
      throw error;
    }
  };

  const createBranch = async (
    branch: EcopaPartnerBranchBase,
    images: EcopaBranchImageCreate[],
    features: EcopaFeature[],
    tags: string[],
    allFeatures: EcopaFeature[]
  ) => {
    try {
      dispatch({ type: "LOADING", payload: {} });
      // post the new branch
      const branchInp = {
        branch,
        images,
        features,
        tags,
      };
      const responseBranch = await postNewEcopaPartnerBranch(
        branchInp,
        allFeatures
      );
      if (responseBranch.error) {
        throw new Error(responseBranch.error);
      }
      if (responseBranch.branches && responseBranch.branches.length > 0) {
        dispatch({
          type: "BRANCH_LOADED",
          payload: { branches: [...responseBranch.branches] },
        });
      } else {
        dispatch({
          type: "ERROR",
          payload: { error: "No branches returned" },
        });
      }
    } catch (error) {
      dispatch({
        type: "ERROR",
        payload: { error: convertErrorToString(error) },
      });
      throw error;
    }
  };

  const updateBranch = async (
    branch: EcopaPartnerBranch,
    allFeatures: EcopaFeature[],
    newImages: EcopaBranchImageCreate[],
    editImages: EcopaBranchImage[],
    features: EcopaFeature[],
    tags: string[]
  ) => {
    try {
      dispatch({ type: "LOADING", payload: {} });
      // post the branch to update
      const branchInp = {
        branch,
        newImages,
        editImages,
        features,
        tags,
      };
      const responseBranch = await putUpdateEcopaPartnerBranch(
        branchInp,
        allFeatures
      );
      if (responseBranch.error) {
        throw new Error(responseBranch.error);
      }
      if (responseBranch.branches && responseBranch.branches.length > 0) {
        dispatch({
          type: "BRANCH_LOADED",
          payload: { branches: [...responseBranch.branches] },
        });
      } else {
        dispatch({
          type: "ERROR",
          payload: { error: "No branches returned" },
        });
      }
    } catch (error) {
      dispatch({
        type: "ERROR",
        payload: { error: convertErrorToString(error) },
      });
      throw error;
    }
  };

  return (
    <PartnerContext.Provider
      value={{
        ...state,
        ...partnerActions,
        initialisePartner,
        createPartner,
        updatePartner,
        createBranch,
        updateBranch,
      }}
    >
      {children}
    </PartnerContext.Provider>
  );
}

export { PartnerProvider, PartnerContext };
