import { useNavigate } from "react-router";
import { Box } from "@mui/material";
import BasicDialog from "./BasicDialog";
import { PATH_AUTH } from "../../routes/paths";

type SignUpPopUpProps = {
  open: boolean;
  onClose: () => void;
};

const SignUpPopUp: React.FC<SignUpPopUpProps> = ({ open, onClose }) => {
  const navigate = useNavigate();
  return (
    <BasicDialog
      open={open}
      onClose={onClose}
      title="¡Regístrate!"
      msg="Crea tu cuenta y únete a nuestra comunidad cafetera sustentable."
      continueAction={{
        active: true,
        msg: "Registrarme",
        actionFn: () => {
          onClose();
          navigate(PATH_AUTH.register);
        },
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <Box
          height={120}
          sx={{ display: "flex", justifyContent: "center" }}
          component={"img"}
          src="/static/assets/ecopa-imagotype.png"
        />
      </Box>
    </BasicDialog>
  );
};

export default SignUpPopUp;
