import type { EcopaConfig, EcopaParsedConfig } from "../types/ecopaConfig";

export const parseRemoteConfig = (config: EcopaConfig[]): EcopaParsedConfig => {
  const parsedConfig: Record<string, any> = {};
  config.forEach((item) => {
    switch (item.config_value_type) {
      case "int":
        parsedConfig[item.config_key] = parseInt(item.config_value);
        break;
      case "float":
        parsedConfig[item.config_key] = parseFloat(item.config_value);
        break;
      case "boolean":
        parsedConfig[item.config_key] = item.config_value === "true";
        break;
      default:
        parsedConfig[item.config_key] = item.config_value;
    }
  });
  return parsedConfig as EcopaParsedConfig;
};
