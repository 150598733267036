import { createContext, useReducer } from "react";
import {
  AppConfigPayloadInterface,
  AppConfigState,
} from "../types/ecopaConfig";
import { getEcopaConfig } from "../api/ecopaConfig";

const initialState: AppConfigState = {
  loading: false,
  loaded: false,
  error: null,
  config: [],
  news: [],
  plans: [],
  features: [],
};

const reducer = (
  state: AppConfigState,
  action: { type: string; payload: AppConfigPayloadInterface }
): AppConfigState => {
  const { type, payload } = action;
  if (action.type === "LOADING") {
    return {
      ...state,
      loading: true,
    };
  } else if (type === "ERROR") {
    if (!payload.error) {
      return {
        ...state,
        loading: false,
        error: "Unexpected error occurred.",
      };
    }
    return {
      ...state,
      loading: false,
      error: payload.error,
    }
  } else if (type === "SETUP_ERROR") {
    if (!payload.error) {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: "Error ocurred setting up the app.",
      };
    }
    return {
      ...state,
      loading: false,
      loaded: true,
      error: payload.error,
    };
  } else if (type === "SETUP_CONFIG") {
    const { config, news, plans, features } = payload;
    const nextState = { ...state };
    if (config) {
      nextState.config = config;
    }
    if (news) {
      nextState.news = news;
    }
    if (plans) {
      nextState.plans = plans;
    }
    if (features) {
      nextState.features = features;
    }
    return {
      ...nextState,
      loading: false,
      loaded: true,
      error: null,
    };
  }
  // default
  return {
    ...state,
  };
};

const appConfigActions = {
  initConfig: () => {},
};

// context

const AppConfigContext = createContext({
  ...initialState,
  ...appConfigActions,
});

interface AppConfigProviderProps {
  children: React.ReactNode;
}

function AppConfigProvider({ children }: AppConfigProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const _fetchConfig = async () => {
    const configResp = await getEcopaConfig();
    if (configResp.error) {
      dispatch({ type: "SETUP_ERROR", payload: { error: configResp.error } });
    } else {
      dispatch({ type: "SETUP_CONFIG", payload: configResp });
    }
  };

  const initConfig = () => {
    dispatch({ type: "LOADING", payload: {} });
    // get news & config
    void _fetchConfig();
  };

  return (
    <AppConfigContext.Provider
      value={{
        ...state,
        ...appConfigActions,
        initConfig,
      }}
    >
      {children}
    </AppConfigContext.Provider>
  );
}

export { AppConfigProvider, AppConfigContext };
